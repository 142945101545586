import React, { useEffect } from "react";
import { PostPOSTApi, PostPUTApi } from "../../../constants/api";
import SideBar from "../../SideBar";
// import ImageUpload from "../../../ImageUpload";
const studentRoles = ["subscriber", "student"]
export default function FormComponent(props) {
  const { FetchData } = props;
  // const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [UserRole, setUserRole] = React.useState(FetchData.meta.user_role_user)
  const [ProfilePicture, setProfilePicture] = React.useState(FetchData.meta.user_profile_picture_user)
  const [SubscriptionStart, setSubscriptionStart] = React.useState(FetchData.meta.user_subscription_start_user)
  const [SubscriptionEnd, setSubscriptionEnd] = React.useState(FetchData.meta.user_subscription_end_user)
  const [PhoneNumber, setPhoneNumber] = React.useState(FetchData.meta.user_phone_number)
  const [VerificationStatus, setVerificationStatus] = React.useState(FetchData.meta.verification_status_user)
  const [userID, setUserID] = React.useState(FetchData.meta.user_id)
  const [userName, setUserName] = React.useState(FetchData.meta.user_name)
  const [Password, setPassword] = React.useState("")
  const [userEmail, setUserEmail] = React.useState(FetchData.meta.user_email)

  const [Datapassword, setDataPassword] = React.useState(FetchData.id == "" ? true : false)

  const [userVerificationHistory, setUserVerificationHistory] = React.useState(FetchData.meta.verify_user_history)

  // const [Name, setName] = React.useState(FetchData.title.rendered)

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  const AfterUserCreate = (id) => {
    const data = {
      "title": Title,
      "status": "publish",
      "author": id,
      "meta": {
        "user_role_user": UserRole,
        "user_profile_picture_user": ProfilePicture,
        "user_subscription_start_user": SubscriptionStart,
        "user_subscription_end_user": SubscriptionEnd,
        "user_phone_number": PhoneNumber,
        "verification_status_user": VerificationStatus,
        "user_id": `${id}`,
        "user_name": userName,
        "user_email": userEmail,
      }
    }
    if (ID === "")
      PostPOSTApi(data, "users_data")
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Book Added Successfully")
          // history.push(`${slugs.student_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      PostPUTApi(data, "users_data", ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  const EditInfo = () => {
    setReload(true)

    if (ID == "") {
      const dataWithPassword = {
        "username": userName,
        "email": userEmail,
        "password": Password,
        "roles": UserRole
      }
      PostPOSTApi(dataWithPassword, "users")
        .then(res => {
          AfterUserCreate(res.data.id)
        })
        .catch(e =>
          console.log(e)
        )
    }
    else {
      if (Datapassword) {
        const dataWithPassword = {
          "password": Password,
          "roles": UserRole,
          "email": userEmail,
        }
        PostPUTApi(dataWithPassword, `users`, `${userID}`)
          .then(res => {
            AfterUserCreate(userID)
          })
          .catch(e =>
            console.log(e)
          )
      }
      else {
        const data = {
          "roles": UserRole,
          "email": userEmail,
        }
        PostPUTApi(data, `users`, `${userID}`)
          .then(res => {
            AfterUserCreate(userID)
          })
          .catch(e =>
            console.log(e)
          )
      }
    }
  }

  const CustomButton = ({ setData, Data, DataValue }) => {
    return (
      <button className="border-0 bg-transparent m-1 "
        onClick={() => {
          setData(DataValue)
        }}
      >
        <div className={Data == DataValue ? "active-option-3" : "passive-option-3"}>
          {DataValue}
        </div>
      </button>
    )
  }


  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Student Information</center></h4>

          <div style={{ border: "1px solid #999", padding: "10px" }}>
            <table>
              <tbody>
                <tr>
                  <td className="col-3">Student Name</td>
                  <td className="col-6">
                    <input className="input-common"
                      value={Title} placeholder="Name"
                      onChange={(event) => { setTitle(event.target.value) }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-3">Student Role</td>
                  <td className="col-6">
                    <CustomButton setData={setUserRole} Data={UserRole} DataValue={"subscriber"} />
                    <CustomButton setData={setUserRole} Data={UserRole} DataValue={"student"} />
                    <CustomButton setData={setUserRole} Data={UserRole} DataValue={"author"} />
                  </td>
                </tr>
                <tr>
                  <td className="col-3">Verification Status</td>
                  <td className="col-6">
                    <CustomButton setData={setVerificationStatus} Data={VerificationStatus} DataValue={"verified"} />
                    <CustomButton setData={setVerificationStatus} Data={VerificationStatus} DataValue={"None"} />
                  </td>
                </tr>
                <tr>
                  <td className="col-3">Subscription Start</td>
                  <td className="col-6">
                    <input className="input-common" type="date"
                      value={SubscriptionStart} placeholder="Subscription Start"
                      onChange={(event) => { setSubscriptionStart(event.target.value) }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-3">Subscription End</td>
                  <td className="col-6">
                    <input className="input-common" type="date"
                      value={SubscriptionEnd} placeholder="Subscription End"
                      onChange={(event) => { setSubscriptionEnd(event.target.value) }}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="col-3">Phone Number</td>
                  <td className="col-6">
                    <input className="input-common"
                      value={PhoneNumber} placeholder="Phone Number"
                      onChange={(event) => { setPhoneNumber(event.target.value) }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-3">User Name</td>
                  <td className="col-6">
                    {ID == "" ?
                      <input className="input-common"
                        value={userName} placeholder="User Name"
                        onChange={(event) => { setUserName(event.target.value) }}
                      />
                      :
                      <div className="input-common" >{userName}</div>
                    }
                  </td>
                </tr>
                <tr>
                  <td className="col-3">User Email</td>
                  <td className="col-6">
                    <input className="input-common"
                      value={userEmail} placeholder="User Email"
                      onChange={(event) => { setUserEmail(event.target.value) }}
                    />
                  </td>
                </tr>
                {/* <tr>
                <td className="col-3">User ID</td>
                <td className="col-6">
                  <input className="input-common"
                    value={userID} placeholder="User ID"
                    onChange={(event) => { setUserID(event.target.value) }}
                  />
                </td>
              </tr> */}
                <tr>
                  <td className="col-3">Password</td>
                  <td className="col-6">
                    {Datapassword ?
                      <input className="input-common"
                        value={Password} placeholder="Password"
                        onChange={(event) => { setPassword(event.target.value) }}
                      />
                      :
                      <div>
                        <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                          onClick={() => {
                            setDataPassword(true)
                            setPassword("")
                          }}
                        >
                          <div className={"passive-option"}>
                            Change Password
                          </div>
                        </button>
                      </div>
                    }
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="row center m-2">
              <div className=" col-3" style={{ padding: "0 10px" }}>
                <div className="sign-in-button-4"
                  onClick={() => {
                    EditInfo()
                  }}
                >
                  Update Student
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {Object.values(userVerificationHistory).length !== 0 &&
        <div className="row">
          <div className="col-sm-2 col-1"></div>
          <div className="col-sm-8 col-10 form-view mb-3">
            <h4><center>Verification History</center></h4>
            <table>
              <tbody>
                <tr>
                  <td >Screenshot</td>
                  <td > Date</td>
                  <td ></td>
                </tr>
                {Object.values(userVerificationHistory).map((item, index) => (
                  <tr key={index}>
                    <td >
                      {item.verify_screen_shot !== "" &&
                        <a href={item.verify_screen_shot} target="_blank">
                          <img src={item.verify_screen_shot} style={{ width: "100px", height: "100px" }} />
                        </a>
                      }
                    </td>
                    <td >{item.verify_date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div >
        </div >
      }
      {
        reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }

    </div >
  )

}