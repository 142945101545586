import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout } from '../../redux/action';
import { PostPOSTApi, PostPOSTNewApi, UserMe, userSignIn, UserSignUp } from '../../constants/api';
import { Link } from 'react-router-dom';

export default function Login() {
  const [userName, setUserName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [phoneNumber, setPhoneNumber] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [confirmPassword, setConfirmPassword] = React.useState("")
  const [reload, setReload] = React.useState(false)
  const [passwordShow, setPasswordShow] = React.useState(false)
  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const [SignInTRegisterF, setSignInTRegisterF] = React.useState(true)
  const [EmailError, setEmailError] = React.useState(false)
  const [passwordError, setPasswordError] = React.useState(false)

  const dispatch = useDispatch()
  const NewUser = () => {
    setReload(true)
    UserSignUp({
      email: email,
      username: userName,
      password: password,
    })
      .then((res) => {
        UserInfo()
      }).catch((err) => {
        console.log(err.response.data.message)
        setAlertcomp(err.response.data.message.replace("Please log in.", ""))
        setAlertShow(true)
        setReload(false)
      }).finally(() => {
        setReload(false)
      })
  }
  const UserInfo = () => {
    setReload(true)
    userSignIn(userName, password)
      .then((res) => {
        userCustomer(res.data)
      }).catch((err) => {
        console.log(err.response.data.message)
        setAlertcomp(err.response.data.message.replace("Lost your password?", ""))
        setAlertShow(true)
      }).finally(() => {
        setReload(false)
      })
  }
  const userCustomer = (data) => {
    setReload(true)
    UserMe(data.token)
      .then((res) => {
        PostPOSTNewApi({
          title: res.data.name,
          meta: {
            user_name: res.data.name,
            user_id: `${res.data.id}`,
            user_role_user: "subscriber",
            user_otp_code_user: phoneNumber
          }
        }, "users_data", data.token)
          .then((response) => {
            dispatch(login(
              data.id,
              userName,
              data.token,
              data.user_email,
              res.data.roles[0],
              ""
            ))
            setSignInTRegisterF(true)
            setReload(false)

          }).catch((err) => {
            console.warn(err);
            console.warn(err.response.data);
            console.log("Response Status:", err.response.status);
            console.log("Response Headers:", err.response.headers);
          })
      }).catch((err) => {
        console.warn(err);
        console.warn(err.response.data);
        console.log("Response Status:", err.response.status);
        console.log("Response Headers:", err.response.headers);
      }).finally(() =>
        setReload(false)
      )
  }
  const AlertComponent = () => {
    return (
      <div className="modal"
        onClick={() => {
          setAlertShow(false)
        }}
      >
        <div>
          <div className="row " style={{ minHeight: "10vh", marginTop: "10vh" }}>
            <div className="col-lg-4 col-1"></div>
            <div className="col-lg-4 col-10 pb-5  bg-white  shadow">
              <div className="p-5">
                <div dangerouslySetInnerHTML={{ __html: Alertcomp }}></div>
              </div>
              <div style={{ display: 'flex', cursor: 'pointer' }} className="center">
                <div className="sign-in-button-1 col-8">
                  OK
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div style={{ backgroundColor: "#fff", minHeight: "100vh", width: "100vw", overflow: 'hidden' }}>
      <div className="row mt-5">
        <div className="col-lg-3 col-1"></div>
        <div className="col-lg-6 col-10 shadow signup-container" style={{ backgroundColor: "#fcebeb" }}>
          <div className="py-5" >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img 
                src={"https://ubtvirtualtest.com/wp-content/uploads/2022/09/logo.png"}
                height={"100px"}
              />
            </div>
            <div className={"font-size-24"} style={{ textAlign: 'center', fontWeight: "bold" }}>
              New to UBT Virtual Test
            </div>
            <div className="center">
              Enter your username, email and password to register.
            </div>
            <div className="row">
              <div className="col-lg-3 col-1" />
              <div className="col-lg-6 col-10 px-5">

                <div className="label-form">Username.</div>
                <input
                  className="col-12 input-style"
                  type="text"
                  value={userName}
                  onChange={(event) => { setUserName(event.target.value) }}
                  placeholder="Enter UserName"
                  required
                /><br />
                <div className="label-form">Phone Number.</div>
                <input
                  className="col-12 input-style"
                  type="text"
                  value={phoneNumber}
                  onChange={(event) => { setPhoneNumber(event.target.value) }}
                  placeholder="Enter Phone Number"
                  required
                /><br />
                <div className="label-form">User Email.</div>
                <input
                  className="col-12  input-style"
                  type="email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value)
                    setEmailError(false)
                  }}
                  placeholder="User email"
                  autoComplete="off"
                  required
                /><br />
                <div className="label-form">Password.</div>
                <input
                  className="col-12  input-style"
                  type={passwordShow ? "text" : "password"}
                  value={password}

                  onChange={(event) => {
                    setPassword(event.target.value)
                    setPasswordError(false)
                  }}
                  placeholder="Enter Password"
                  required
                /><br />
                <div className="label-form">Confirm Password.</div>
                <input
                  className="col-12  input-style"
                  type="password"
                  value={confirmPassword}
                  onChange={(event) => {
                    setConfirmPassword(event.target.value)
                    setPasswordError(false)
                  }}
                  placeholder="Enter Confirm Password"
                  required
                /><br />
                {EmailError &&
                  <div className="label-form">Email not valid.</div>
                }
                {passwordError &&
                  <div className="label-form">Password and Confirm Password not same.</div>
                  // <Text style={[styles.smallNormalText, { textAlign: 'center', color: "#f00" }]}>Password and Confirm Password not same.</Text>
                }
                <div className="row">
                  <div className="row " style={{ cursor: 'pointer', width: "100%" }}
                    onClick={() => setPasswordShow(!passwordShow)}
                  >
                    <div className="showPassword" style={{ width: "10%", }}>
                      {!passwordShow ?
                        <i className="fa fa-square-o" />
                        :
                        <i className="fa fa-check-square-o" />
                      }
                    </div>
                    <div className="showPassword" style={{ width: "80%" }}>Show password</div>
                  </div>
                </div>
                <a href="tel:+9779815393939" className="Link">
                  <h6 >
                    <center>Contact No: +9779815393939</center>
                  </h6>
                </a>
                <div className="center">
                  <button onClick={() => {
                    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                      if (password !== confirmPassword || password == "")
                        setPasswordError(true)
                      else
                        NewUser()
                    }
                    else
                      setEmailError(true)
                  }} className="sign-in-button-1 col-8">
                    Register
                  </button>
                  <br />
                  <Link to={"/login"}>
                    <button className="sign-in-button-2 col-8">
                      Go to Sign In
                    </button>
                  </Link>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {AlertShow &&
        <AlertComponent />
      }
    </div>
  )
}