import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import SecondScreenComponent from './SecondScreen';
import './styles.css'
export default function FinalScreenExam(props) {
  const {
    ExamData,
    QuestionsData,
    AnswerArray,
    userDetails,
    ResultData
  } = props;
  const [totalAttempt, setTotalAttempt] = React.useState(0)
  const [correctAnswer, setCorrectAnswer] = React.useState(0)
  useEffect(() => {
    var attempt = 0;
    var correct = 0;
    for (let i = 0; i < AnswerArray.length; i++) {
      if (AnswerArray[i].solved == 1)
        attempt++;
      if (AnswerArray[i].correct_answer == AnswerArray[i].answerChoosen)
        correct++
      setTotalAttempt(attempt);
      setCorrectAnswer(correct)
    }
  }, [])
  return (
    <div style={{ padding: "20px" }}>
      <h2>Congratulations! </h2>
      <br />
      <br />

      <center>Name: {userDetails.name}</center>
      <center>Exam: {ExamData.title.rendered}</center>
      <br />
      <br />

      <div className="row row-data">
        <div className="col-8">Total Questions</div>
        <div className="col-4">{Object.keys(QuestionsData).length}</div>
      </div>
      <div className="row row-data">
        <div className="col-8">Total Attempt</div>
        <div className="col-4">{totalAttempt}</div>
      </div>
      <div className="row row-data">
        <div className="col-8">Total Correct</div>
        <div className="col-4">{correctAnswer}</div>
      </div>
      <div className="row row-data">
        <div className="col-8">Percentage Obtained</div>
        <div className="col-4">{(correctAnswer / AnswerArray.length * 100).toFixed(2)}%</div>
      </div>
      <div className="row center">
        Verify account to see Details results
      </div>
      <div className="row center">
        <Link to={`/`} className="col-lg-2 col-12 Link">
          <div className="col-12 sign-in-button-1">
            Go to Home
          </div>
        </Link>
      </div>
    </div>
  )
}