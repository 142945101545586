import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PostGETApiAuth, PostPUTApi } from '../../constants/api';

export default function SingleUserData(props) {
  const {
    userData,
    setReload,
    fetchExam,
    setListT,
    setChoose
  } = props;
  const [UserDataUser, setUserData] = React.useState({});
  const [reloadData, setReloadData] = React.useState(true);
  const [verifyTime, setVerifyTime] = React.useState("3 months");
  const fetchUser = (params) => {
    setReload(true)
    setReloadData(true)
    PostGETApiAuth(params, `users/${userData.meta.user_id}`)
      .then((res) => {
        setUserData(res.data)
        console.log(res.data)
        setReload(false)
        setReloadData(false)
      })
      .catch(err => {
        setReloadData(false)
        console.log(err)
        console.log(err.response.data)
      })
  }
  useEffect(() => {
    setReload(true)
    fetchUser({})

  }, [])
  const date = new Date();
  const monthnew = (date.getMonth() + 1 + verifyTime) % 12;
  const month = monthnew < 9 ? `0${monthnew}` : `${monthnew}`
  const day = date.getDate() < 9 ? `0${date.getDate()}` : `${date.getDate()}`
  const year = date.getMonth() < (11 - verifyTime) ? `${date.getUTCFullYear()}` : `${date.getUTCFullYear() + 1}`

  const VerifyUser = (verify) => {
    setReload(true)
    const date = new Date();
    const monthToday = date.getMonth() < 9 ? `0${(date.getMonth() + 1)}` : `${date.getMonth() + 1}`
    const dayToday = date.getDate() < 9 ? `0${date.getDate()}` : `${date.getDate()}`

    const data1 = verify ? { roles: "student" } : { roles: "subscriber" }
    const data2 = verify ? {
      meta: {
        user_subscription_start_user: `${date.getUTCFullYear()}-${monthToday}-${dayToday}`,
        user_subscription_end_user: `${year} - ${month} - ${day}`,
        user_role_user: "student",
        verification_status_user: "verified",
      }
    } : {
      meta: {
        user_role_user: "subscriber",
        verification_status_user: "",
        user_subscription_start_user: ``,
        user_subscription_end_user: ``,
      }
    }
    PostPUTApi(data2, "users_data", userData.id)
      .then((res) => {
        PostPUTApi(data1, "users", UserDataUser.id)
          .then((res) => {
            setReload(false)
            if (verify)
              setChoose(0)
            else
              setChoose(1)
            setListT(true)
            if (verify)
              fetchExam({
                status: "any",
                "filter[meta_query][1][key]": "user_role_user",
                "filter[meta_query][1][value][0]": "student",
                "filter[meta_query][1][compare]": "=",
              })
            else
              fetchExam({
                status: "any",
                "filter[meta_query][1][key]": "user_role_user",
                "filter[meta_query][1][value][0]": "subscriber",
                "filter[meta_query][1][compare]": "=",
                "filter[meta_query][relation]": "AND",
                "filter[meta_query][1][key]": "verification_status_user",
                "filter[meta_query][1][value][0]": "",
                "filter[meta_query][1][compare]": "=",
              })
          })
      }).catch((err) => {
      })
  }
  const CustomButton = ({ setData, Data, DataValue }) => {
    return (
      <button className="border-0 bg-transparent m-1 "
        onClick={() => {
          setData(DataValue)
        }}
      >
        <div className={Data == DataValue ? "active-option" : "passive-option"}>
          {DataValue} months
        </div>
      </button>
    )
  }
  return (
    <div className="col-md-7">
      {!reloadData &&
        <div style={{ padding: "10px", margin: "10px 0px", border: "1px solid black" }}>
          <div className="center">
            {userData.meta.user_profile_picture_user === "" ?
              <img src={`https://ubtvirtualtest.com/wp-content/uploads/2022/05/User_font_awesome.png`}
                height="70px"
              />
              :
              <img src={`${userData.meta.user_profile_picture_user}`} className={"height-width-150 border border-dark"} />
            }
          </div>
          <br />
          <br />
          <b>UserName:</b> {userData.meta.user_name}<br />
          <b>Email:</b> {UserDataUser.email}<br />
          <b>PhoneNumber:</b> {userData.meta.user_otp_code_user}<br />
          {userData.meta.user_role_user == "student" &&
            <div >
              <div><b>UserRole:</b> Student<br /></div>
              <div><b>Subscription Started: {userData.meta.user_subscription_start_user}</b></div>
              <div><b>Subscription Ends In: {userData.meta.user_subscription_end_user}</b></div>
              <div style={{ color: "#00f" }}><b>Status: Verified</b></div>
              {userData.meta.verification_status_user == "verified" &&
                <div>
                  <div className="center">
                    <button className="sign-in-button-1"
                      onClick={() => {
                        VerifyUser(false)
                      }}
                    >Remove from Student</button>
                  </div>
                </div>
              }
            </div>
          }
          {/* {JSON.stringify(userData.meta.user_otp_code)} */}
          {userData.meta.user_role_user == "subscriber" &&
            <div>
              <b>UserRole:</b> Subscriber<br />
              <div><b>Status:
                {userData.meta.verification_status_user == "" ?
                  <span style={{ color: "#f00" }}> Unverified</span> :
                  <span style={{ color: "#000" }}> Pending</span>
                }</b>
                <br />
                <br />

                {userData.meta.verification_status_user}
                <label htmlFor="uname" className="font-size-14"><b>Verify time</b></label><br />
                <CustomButton setData={setVerifyTime} Data={verifyTime} DataValue={3} />
                <CustomButton setData={setVerifyTime} Data={verifyTime} DataValue={6} />
                <CustomButton setData={setVerifyTime} Data={verifyTime} DataValue={12} />
                <br/>End Date : {year} - {month} - {day}

                {/* {userData.meta.verification_status_user == "pending" && */}
                <div>
                  <div><b>Verification Image:</b></div>
                  <img src={userData.meta.verify_screenshot_user}
                    style={{ height: "100px", border: "1px solid black", margin: "10px" }}
                  />
                  <br />
                  <div className="center">
                    <button className="sign-in-button-1"
                      onClick={() => {
                        VerifyUser(true)
                      }}
                    >Verify Now</button>
                  </div>
                </div>
                {/* } */}

              </div>
            </div>
          }



        </div>
      }
    </div>
  )
}
