import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PostGETApiAuth, PostPUTApi, UserMe, UserMeEdit } from '../../constants/api';
import { login, logout } from '../../redux/action';
import store from '../../redux/store';
import SinglePicturesUpload from '../AddExam/SingleArray';
import SideBar from '../SideBar';

export default function Profile() {
  const dispatch = useDispatch()
  const [Name, setName] = React.useState("")
  const [Photo, setPhoto] = React.useState("")
  const [PhoneNumber, setPhoneNumber] = React.useState("")
  const [user, setUser] = React.useState({ roles: ['subscriber'] })
  const [user2, setUser2] = React.useState({})
  const [usersDataID, setusersDataID] = React.useState("")
  const [UserFetch, setUserFetch] = React.useState(false)
  const [SubscriptionPressed, setSubscriptionPressed] = React.useState(false)
  const [PaymentMethod, setPaymentMethod] = React.useState("")
  const [ScreenShotUrl, setScreenShotUrl] = React.useState("")
  const [SubscriptionVerifyDone, setSubscriptionVerifyDone] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const [BankImageSelect, setBankImageSelect] = React.useState(false)
  const [BankImageUrl, setBankImageUrl] = React.useState("")
  const [Email, setEmail] = React.useState("")

  const LoginState = useSelector((state) => state.LoginState);
  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const [editProfileModal, setEditProfileModal] = React.useState(false)

  const UserDetails = () => {
    setUserFetch(false)
    setReload(true)
    UserMe(LoginState.token)
      .then((res) => {
        console.log(res.data)
        const photo = res.data.meta["user_profile_picture"]
        setName(res.data.name)
        setUser(res.data)
        PostGETApiAuth({
          status: "any",
          "filter[meta_query][1][key]": "user_id",
          "filter[meta_query][1][value][0]": res.data.id,
          "filter[meta_query][1][compare]": "=",
        }, "users_data")
          .then((response) => {
            if (response.data.length !== 0) {
              setUser2(response.data[0])
              setusersDataID(response.data[0].id)
              setUserFetch(true)
              setPhoneNumber(response.data[0].meta.user_phone_number)
              setEmail(response.data[0].meta.user_email)
              setPhoto(response.data[0].meta["user_profile_picture_user"])
      }
            else {
              logout()
            }
          })
        setReload(false)

      }).catch(() => {

      })
  }
  const EditUser = () => {
    setReload(true)
    PostPUTApi(
      {
        meta: {
          user_profile_picture_user: Photo,
          user_phone_number: PhoneNumber
        }
      },
      "users_data", usersDataID
    ).then(res => {
      setReload(false)
    }).catch(() => {

    })
  }
  const UserDetailsSubscriptionEdit = () => {
    setReload(true)
    const date = new Date();
    const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
    const day = date.getDate() < 9 ? `0${date.getDate()}` : `${date.getDate()}`

    const data2 = {
      meta: {
        verify_screenshot_user: ScreenShotUrl,
        verification_status_user: "pending",
        verify_user_history: {
          ...user2.meta.verify_user_history,
          [`item-${Object.keys(user2.meta.verify_user_history).length}`]: {
            verify_screen_shot: ScreenShotUrl,
            verify_date: `${date.getUTCFullYear()}-${month}-${day}`,
            verify_otp: ""
          }
        }
      }
    }
    console.log(usersDataID)
    PostPUTApi(data2, "users_data", usersDataID)
      .then((res) => {
        setUser2(res.data)
        setReload(false)
        setSubscriptionVerifyDone(true)
        console.log(res.data)
      }).catch((err) => {
        console.warn(err);
        console.warn(err.response.data);
      })
  }
  useEffect(() => {
    UserDetails()
  }, [])
  const AlertComponent = () => {
    return (
      <div className="modal"
        onClick={() => {
        }}
      >
        <div>
          <div className="row " style={{ minHeight: "10vh", marginTop: "10vh" }}>
            <div className="col-lg-4 col-1"></div>
            <div className="col-lg-4 col-10 pb-5  bg-white  shadow">
              <div className="p-5">
                <div dangerouslySetInnerHTML={{ __html: Alertcomp }}></div>
              </div>
              <div style={{ display: 'flex', cursor: 'pointer' }} className="center">
                <div className="sign-in-button-1 col-8">
                  OK
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div
      style={{ backgroundColor: "#fff" }}
      className="row"
    >
      <div className="col-md-2 ">
        <SideBar DashboardList={3} />
      </div>
      {UserFetch &&
        <div className="col-md-10 ">
          <div className="col-12 center">
            <div className="col-sm-3 col-6" >
              <button className="sign-in-button-1 col-10  ms-2"
                onClick={() => { dispatch(logout()) }}
              >Log out</button>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7">
              <section className=" bg-white shadow m-3">
                <div className="col-12">
                  <div style={{ padding: "20px" }}>
                    {Photo == "" ?
                      <div className="">
                        <img src={"https://ibuy-group-of-company.com/media/2022/03/user-icon.webp"}
                          height={"100px"}
                        />
                      </div>
                      :
                      <div className="">
                        <img src={`${Photo}`}
                          height={"100px"}
                        />
                      </div>
                    }
                    <br />
                    UserName: {user.name}<br />
                    Email: {Email}<br />
                    Phone Number: {PhoneNumber}<br />
                  </div>
                </div>
                <div className="col-12 center" style={{ margin: "20px" }}>
                  <div className="col-sm-3 col-6" >
                    <button className="sign-in-button-1
                     col-10  ms-2"
                      onClick={() => { setEditProfileModal(true) }}
                    >Edit Profile</button>
                  </div>
                </div>
                <br />
              </section>
              <section className="bg-white shadow m-3 p-3">
                <a href="tel:+9779849677956" className="Link">
                  <h4>
                    For more information Contact: +9779849677956
                  </h4>
                </a>
              </section>
              {UserFetch &&
                <section className="bg-white shadow m-3 p-3">
                  <h3>Subscription</h3>
                  {user.roles[0] == "author" &&
                    <div>Role: Admin</div>
                  }
                  {user.roles[0] == "student" &&
                    <div>
                      <div>Role: Verified user</div>
                      <div>Subscription Start Date: {user2.meta.user_subscription_start_user}</div>
                      <div>Subscription End Date: {user2.meta.user_subscription_end_user}</div>
                      {user2.meta.verification_status_user !== "" &&
                        <div>Verification Status: {user2.meta.verification_status_user}</div>
                      }
                    </div>
                  }
                  {user.roles[0] == "subscriber" &&
                    <div>
                      {user2.meta.verification_status_user == "pending" ?
                        <div>
                          <div><b>Role:</b> Pending Unverified user</div>
                          <br />
                          <div>
                            Your Request for the subscription is done<br />
                            Please wait for the admin conformation to verify your account
                          </div>
                          <br />
                          {user2.meta.verify_screenshot_user !== "" &&
                            <div>
                              <b>Verification Image</b><br />
                              <img src={user2.meta.verify_screenshot_user}
                                height={"300px"}
                              />
                            </div>
                          }
                        </div>
                        :
                        <div>
                          <div><b>Role:</b> Unverified user</div>
                          <div>To subscribe for 1 year cost is Rs 2000</div>
                          <div className="col-sm-4 col-6 my-3" >
                            <button className="sign-in-button-2 col-10  ms-2"
                              onClick={() => {
                                setSubscriptionPressed(true)
                              }}
                            >Buy Now</button>
                          </div>
                          {SubscriptionPressed &&
                            <div>
                              <h4>Payment Method</h4>
                              <div style={{ display: 'flex', cursor: 'pointer', padding: "5px" }}
                                onClick={() => {
                                  setPaymentMethod("manual")
                                }}
                              >
                                {PaymentMethod == "manual" ?
                                  <i class="fa fa-check-circle-o"></i>
                                  : <i class="fa fa-circle-o"></i>
                                }
                                <div className={PaymentMethod == "manual" ? "active-option2" : "passive-option2"}>
                                  Manual Payment
                                </div>
                              </div>
                              {PaymentMethod == "esewa" &&
                                <div className="col-sm-4 col-6 my-3" >
                                  <button className="sign-in-button-2 col-10  ms-2"
                                    onClick={() => {
                                      setSubscriptionPressed(true)
                                    }}
                                  >Proceed Payment</button>
                                </div>
                              }
                              {PaymentMethod == "manual" &&
                                <div className="my-3" >
                                  Please upload the screenshot of payment below
                                  <SinglePicturesUpload
                                    ImageUrl={ScreenShotUrl}
                                    setImageUrl={setScreenShotUrl}
                                  />
                                  {ScreenShotUrl !== "" &&
                                    <div className="col-sm-4 col-6 my-3" >
                                      <button className="sign-in-button-2 col-10  ms-2"
                                        onClick={() => {
                                          UserDetailsSubscriptionEdit()
                                        }}
                                      >Proceed Subscription</button>
                                    </div>
                                  }

                                </div>
                              }
                            </div>
                          }
                        </div>
                      }
                    </div>
                  }

                </section>

              }
            </div>
           </div>
        </div>
      }

      {editProfileModal &&
        <section className="modal" style={{ padding: "10vh" }}>
          <div className="row">
            <div className="col-lg-3">

            </div>
            <div className="col-lg-6" style={{ backgroundColor: "#fff", padding: "30px" }}>
              <div style={{ textAlign: 'right', fontSize: 22, padding: 10, cursor: 'pointer' }}
                onClick={() => {
                  setEditProfileModal(false)
                }}
              >
                <i className="fa fa-close" />
              </div>
              Edit Profile Picture
              <SinglePicturesUpload
                ImageUrl={Photo}
                setImageUrl={setPhoto}
              />
              <div>
                <div style={{ margin: "10px 0 3px" }}><b>Edit Phone Number</b></div>
                <div className="row" >
                  <input className="my-2 p-2 px-2 col-11" style={{ margin: "30px" }}
                    type="text"
                    value={PhoneNumber}
                    onChange={(event) => {
                      setPhoneNumber(event.target.value.replace(/\D/g, ""))
                    }}
                    placeholder="Edit Phone Number"
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-3" />
                <div className="sign-in-button-1 col-6"
                  onClick={() => {
                    EditUser()
                    setEditProfileModal(false)
                  }}
                >
                  Update Profile
                </div>
              </div>
            </div>
            <div className="col-lg-3">

            </div>
          </div>
        </section>

      }

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {AlertShow &&
        <AlertComponent />
      }
    </div>
  )
}