import React, { useEffect } from 'react';
import ThridScreenComponent from './ThridScreen';
import mediaPlayedJSON from './media.json';
export default function SecondScreenComponent(props) {
  const {
    QuestionsEditKey, setQuestionsEditKey,
    QuestionsEditData, setQuestionsEditData,
    QuestionsData, setQuestionsData,
    QuestionsEditMode, setQuestionsEditMode,
    TimingStarted, setTimingStarted,
    ExamData, AnswerArray,
    setChoose,
    SubmitExam
  } = props;
  const [Timing, setTiming] = React.useState(ExamData.meta.exam_time);
  const [QuestionsEditMode1, setQuestionsEditMode1] = React.useState(false);
  const [TimerValue, setTimerValue] = React.useState("");
  const [ArrayIndex, setArrayIndex] = React.useState("");
  const [QuestionsSolved, setQuestionsSolved] = React.useState(0);
  const [mediaPlayed, setMedaiPlayed] = React.useState(mediaPlayedJSON)
  const [isPlayingMedia, setIsPlayingMedia] = React.useState(false)

  useEffect(() => {
    const date = new Date();
    const dateEnds = date.getTime() + Number(Timing) * 60 * 1000
    const intervalId = setInterval(() => {  //assign interval to a variable to clear it.
      if (TimingStarted && Timing !== "") {
        const datenow = new Date();
        const difference = parseInt((dateEnds - datenow.getTime()) / 1000);
        if (difference > 0) {
          const hoursValue = parseInt(difference / 60 / 60 % 24) < 10 ? `0${parseInt(difference / 60 / 60 % 24)}` : parseInt(difference / 60 / 60 % 24)
          const min = parseInt(difference / 60 % 60) < 10 ? `0${parseInt(difference / 60 % 60)}` : parseInt(difference / 60 % 60)
          const seconds = parseInt(difference % 60) < 10 ? `0${parseInt(difference % 60)}` : parseInt(difference % 60)
          setTimerValue(`${hoursValue} : ${min} : ${seconds}`)
        }
        else {
          setTimerValue("")
          SubmitExam()
        }
      }
    }, 1000)
    return () => clearInterval(intervalId); //This is important
  }, [])



  return (
    <div>
      <div className="row " style={{ backgroundColor: "#156cf2", color: "#fff", padding: "0px", margin: "0px 0" }}>
        <div className="col-md-6 question-solved" >
          Questions Solved: {QuestionsSolved}
        </div>
        <div className="col-md-6 timer-value" >
          Time Remaining: {TimerValue}
        </div>

      </div>
      {QuestionsEditMode ?
        <div>
          <div style={{ padding: "20px", }}>
            <span style={{
              border: "2px solid black",
              margin: "10px", width: "20px", height: "20px", backgroundColor: "#156cf2"
            }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            &nbsp;Solved
            <span style={{
              border: "2px solid black",
              margin: "10px", width: "20px", height: "20px", backgroundColor: "#fff"
            }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            &nbsp;Unsolved
          </div>
          <div className="row center">
            <div className="col-md-6 row center" >
              {Object.keys(QuestionsData).map((item, index) => {
                if (index < 20)
                  return (
                    <div key={index} className="col-2 m-1 p-2 center"
                      style={{
                        backgroundColor: !AnswerArray[index].solved == 1 ? "#fff" : "#156cf2",
                        fontWeight: "bold",
                        border: "2px solid black",
                        cursor: 'pointer',
                        color: !AnswerArray[index].solved == 1 ? "#000" : "#fff",
                      }}
                      onClick={() => {
                        setQuestionsEditData(QuestionsData[item])
                        setQuestionsEditKey(item)
                        setQuestionsEditMode(false)
                        setArrayIndex(index)

                      }}
                    >
                      {QuestionsData[item].question_number}
                    </div>
                  )
              })}
            </div>
            <div className="col-md-6 row">
              {Object.keys(QuestionsData).map((item, index) => {
                if (index >= 20)
                  return (
                    <div key={index} className="col-2 m-1 p-2 center"
                      style={{
                        backgroundColor: !AnswerArray[index].solved == 1 ? "#fff" : "#156cf2",
                        fontWeight: "bold",
                        border: !AnswerArray[index].solved == 1 ? "2px solid black" : "2px solid #156cf2",
                        cursor: 'pointer',
                        color: !AnswerArray[index].solved == 1 ? "#000" : "#fff",
                      }}
                      onClick={() => {
                        setQuestionsEditData(QuestionsData[item])
                        setQuestionsEditKey(item)
                        setQuestionsEditMode(false)
                        setArrayIndex(index)
                      }}
                    >
                      {QuestionsData[item].question_number}
                    </div>
                  )
              })}
            </div>
            <div className="row">
              <div className="col-lg-4 col-12" />
              <div className="col-lg-4 col-12 center">
                <button className="sign-in-button-11 col-lg-8 col-7"
                  onClick={() => {
                    setChoose(2)
                    // setTimingStarted(true)
                    SubmitExam()
                  }}
                >
                  Submit and Finish Exam
                </button>
              </div>
            </div>
          </div>
        </div>
        :
        <div>

          {!QuestionsEditMode1 ?
            <ThridScreenComponent
              keyValue={QuestionsEditKey}
              oneQuestionData={QuestionsEditData}
              AllQuestionData={QuestionsData}
              setAllQuestionData={setQuestionsData}
              AnswerArray={AnswerArray}
              ArrayIndex={ArrayIndex}
              setQuestionsSolved={setQuestionsSolved}
              QuestionsSolved={QuestionsSolved}
              mediaPlayed={mediaPlayed} setMedaiPlayed={setMedaiPlayed}
              setIsPlayingMedia={setIsPlayingMedia}
              isPlayingMedia={isPlayingMedia}

            />
            :
            <div style={{ minHeight: "72vh" }}>
            </div>
          }
          <div className="row mx-5 m-3" style={{ backgroundColor: "#fff" }} >
            <div className="col-md-4">
              {ArrayIndex !== 0 &&
                <div className="col-12 previous-question" style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (ArrayIndex !== 0) {
                      if (!isPlayingMedia) {
                        setQuestionsEditMode1(true)
                        setTimeout(() => {
                          setQuestionsEditMode1(false)
                        }, 0)
                        setQuestionsEditData(QuestionsData[Object.keys(QuestionsData)[ArrayIndex - 1]])
                        setQuestionsEditKey(Object.keys(QuestionsData)[ArrayIndex - 1])
                        setArrayIndex(ArrayIndex - 1)
                      }
                    }
                  }}
                >
                  <button className={!isPlayingMedia ? "sign-in-button-11" : "sign-in-button-1-media-on"}>
                    <i className="fa fa-chevron-left" />&nbsp; Previous Question
                  </button>
                </div>
              }
            </div>
            <div className="col-md-4 " style={{ textAlign: 'center', cursor: "pointer" }}
              onClick={() => {
                if (!isPlayingMedia) {
                  setQuestionsEditMode(true)
                }
              }}
            >
              <button className={!isPlayingMedia ? "sign-in-button-11" : "sign-in-button-1-media-on"}>
                <i className="fa fa-list" />&nbsp;   Question List
              </button>
            </div>
            {ArrayIndex !== Object.keys(QuestionsData).length - 1 &&
              <div className="col-md-4 question-list" style={{ cursor: "pointer" }}
                onClick={() => {
                  if (!isPlayingMedia) {
                    setQuestionsEditMode1(true)
                    setTimeout(() => {
                      setQuestionsEditMode1(false)
                    }, 0)
                    setQuestionsEditData(QuestionsData[Object.keys(QuestionsData)[ArrayIndex + 1]])
                    setQuestionsEditKey(Object.keys(QuestionsData)[ArrayIndex + 1])
                    setArrayIndex(ArrayIndex + 1)
                  }
                }}
              >
                <button className={!isPlayingMedia ? "sign-in-button-11" : "sign-in-button-1-media-on"}>
                  Next Question&nbsp;  <i className="fa fa-chevron-right" />
                </button>
              </div>
            }
          </div>



        </div>
      }
    </div>
  )
}