import React from 'react';

export const NotFound = () => {
	return (
		<div className="m-1"
		style={{height:"50vh"}}
		>
			<header className="bg-white"
			>
				<h1 className="p-5">The page can’t be found.</h1>
			</header>
			<div className="px-5">
				<p>It looks like nothing was found at this location.</p>
			</div> </div>
	)
}