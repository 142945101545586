import React from "react";
import SideBar from "../SideBar";
import FormComponent from "./Components/Form";
export default function NewStudent() {
  const FetchData = {
    "id": "",
    "title": {
      "rendered": ""
    },
    "meta": {
      "user_role_user": "",
      "user_profile_picture_user": "",
      "user_subscription_start_user": "",
      "user_subscription_end_user": "",
      "user_email": "",
      "user_phone_number": "",
      "user_otp_code_user": "",
      "verify_screenshot_user": "",
      "verification_status_user": "none",
      "user_id": "",
      "user_name": "",
      "verify_user_history": []
    },
  };
  return (
    <div>
      <div
        style={{ backgroundColor: "#fff" }}
        className="row"
      >
        <div className="row">
          <div className="col-2">
            <SideBar DashboardList={5} />

          </div>
          <div className="col-10" style={{ padding: "10px" }}>


            <div>
              <div className="row" style={{ padding: "30px 30px" }}>
                <div className="col-md-8 button">
                  <h2>New Student</h2>
                </div>
                <div className="col-md-4" style={{ textAlign: 'right' }}>
                  <a href="/">Dashboard</a> / <a href={"all-students"}>All Students</a>
                </div>
              </div>
              <FormComponent
                FetchData={FetchData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}