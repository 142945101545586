import { combineReducers } from 'redux';
import { LOGIN, LOGOUT, RETRIEVE_TOKEN, STORE_NONCE, LOCATION } from './types';

const initialLoginState = {
  isLoading: true,
  userID: null,
  userName: null,
  token: null,
  userEmail: null,
  userPhoneNumber: null,
};

const LoginState = (state = initialLoginState, action) => {
  switch (action.type) {
    case RETRIEVE_TOKEN:
      return {
        ...initialLoginState,
        isLoading: false,
        userID: action.id,
        userName: action.userName,
        token: action.token,
        userEmail: action.userEmail,
        userRole: action.userRole,
      };
    case LOGIN:
      return {
        ...initialLoginState,
        isLoading: false,
        userID: action.id,
        userName: action.userName,
        token: action.token,
        userEmail: action.userEmail,
        userRole: action.userRole,
      };
    case LOGOUT:
      return {
        ...initialLoginState,
        isLoading: false,
      };
    default:
      return state
  }
}
const NonceValue = {
  nonce: null
};
const NonceState = (state = NonceValue, action) => {
  switch (action.type) {
    case STORE_NONCE:
      return {
        ...NonceValue,
        nonce: action.nonce
      }
    default:
      return NonceValue;
  }
}

const Location = {
  lat: null,
  lng: null,
  district: null,
  id: 0,
  province: null,
  provinceId: 0

}
const LocationState = (state = Location, action) => {
  switch (action.type) {
    case LOCATION:
      return {
        ...Location,
        lat: action.lat,
        lng: action.lng,
        district: action.district,
        id: action.id,
        province: action.province,
        provinceId: action.provinceId
      }
    default:
      return Location;
  }
}


const BillingAddress = {
  "first_name": "",
  "last_name": "",
  "company": "",
  "address_1": "",
  "address_2": "",
  "city": "",
  "state": "",
  "postcode": "",
  "country": "",
  "email": "",
  "phone": ""
}

const BillingState = (state = NonceValue, action) => {
  switch (action.type) {
    case STORE_NONCE:
      return {
        ...NonceValue,
        nonce: action.nonce
      }
    default:
      return NonceValue;
  }
}

export default combineReducers({
  LoginState: LoginState,
  NonceState: NonceState,
  LocationState: LocationState
});

