import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { login, logout } from '../redux/action';
import './Header.css'

export default function Header() {
  const dispatch = useDispatch()
  const LoginState = useSelector((state) => state.LoginState);

  return (
    <div className="row"
      style={{ backgroundColor: "#fcebeb" }}
      // style={{ backgroundColor: "#bafc03" }}
      // style={{ backgroundColor: "#fff", }}

    >
      <div className="col-lg-3 col-12" >
        <a className="heading-logo-style"
          href="https://skysewa.com/"
        // href="/"
        >
          <img
               src={"http://skysewa.com/wp-content/uploads/2023/01/sky.png"}
            
            height={"70px"}
          />
        </a>
      </div>
      <div className="col-lg-6"></div>
      <section className="col-lg-3 col-md-0 col-sm-0 center row">
        <div className="font-weight-700" style={{ width: "50%", color: "#fff" }}>
          <button className="border-0 bg-transparent">
            <Link to={`/`} className="Link ">
              <span style={{ color: "#000" }}>
                <i className="fa fa-list"></i>
                &nbsp;Dashboard
              </span>
            </Link>
          </button>
        </div>
        <div className="font-weight-700" style={{ width: "50%" }}>
          <button className="border-0 bg-transparent">
            <Link to={`/Profile`} className="Link " >
              <span style={{ color: "#000" }}>
                <i className="fa fa-user"></i>
                {LoginState.userName.length > 10 ?
                  ` ${LoginState.userName.slice(0, 6)}...`
                  :
                  ` ${LoginState.userName}`
                }
              </span>
            </Link>
          </button>
        </div>
      </section>

      {/* <button className="sign-in-button-2 col-3  ms-2"
        onClick={() => { dispatch(logout()) }}
      >Log out</button> */}
    </div>
  )
}