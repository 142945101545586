import React, { useEffect } from 'react';
import SingleMediaUpload from './AllTypeMedia';


export default function QuestionComponent(props) {
  const {
    keyValue,
    oneQuestionData,
    PostExam,
    AllQuestionData,
    setAllQuestionData
  } = props;
  const [question, setQuestion] = React.useState(oneQuestionData.question)
  const [questionDescription, setQuestionDescription] = React.useState(oneQuestionData.question_description)
  const [option1, setOption1] = React.useState(oneQuestionData.option_1)
  const [option2, setOption2] = React.useState(oneQuestionData.option_2)
  const [option3, setOption3] = React.useState(oneQuestionData.option_3)
  const [option4, setOption4] = React.useState(oneQuestionData.option_4)
  const [answerDescription, setAnswerDescription] = React.useState(oneQuestionData.answer_description)
  const [correctAnswer, setCorrectAnswer] = React.useState(oneQuestionData.correct_answer)
  const [questionMedia, setQuestionMedia] = React.useState(oneQuestionData.question_media)
  const [questionDescriptionType, setQuestionDescriptionType] = React.useState(oneQuestionData.question_description_type)
  const [questionMediaType, setQuestionMediaType] = React.useState(oneQuestionData.question_media_type)
  const [answerMediaType, setAnswerMediaType] = React.useState(oneQuestionData.answer_media_type)
  const [MediaUpload, setMediaUpload] = React.useState("")
  const [MediaUploadType, setMediaUploadType] = React.useState("")
  const [editQuestion, setEditQuestion] = React.useState(false)
  const [CopyToClipBoard, setCopytoClipBoard] = React.useState(false)
  const CustomButton = ({ setData, Data, DataValue }) => {
    return (
      <button className="border-0 bg-transparent m-1 "
        onClick={() => {
          setData(DataValue)
        }}
      >
        <div className={Data == DataValue ? "active-option" : "passive-option"}>
          {DataValue}
        </div>
      </button>
    )
  }

  return (
    <div className="row">
      <div className="col-lg-8">
        <div style={{ backgroundColor: "#fcebeb", margin: "10px", padding: "20px" }}>
          <h3>Question Number: {oneQuestionData.question_number}</h3>
          <div>
            <label htmlFor="uname" className="font-size-14"><b>Question</b></label><br />
            <input
              className="col-10 border-1 border-dark rounded-2 p-1 my-2"
              type="text"
              value={question}
              onChange={(event) => {
                setQuestion(event.target.value)
                setEditQuestion(false)
              }}
              placeholder="Enter Question"
              required
            /><br />
          </div>
          <div>
            <label htmlFor="uname" className="font-size-14"><b>Question Description</b></label><br />
            <textarea
              className="col-10 border-1 border-dark rounded-2 p-1 my-2"
              type="text"
              rows="5" cols="30"
              value={questionDescription}
              onChange={(event) => {
                setEditQuestion(false)
                setQuestionDescription(event.target.value)
              }}
              placeholder="Enter Question Description"
              required
            /><br />
            {questionDescriptionType != "text" &&
              <SingleMediaUpload
                ImageUrl={questionDescription}
                setImageUrl={setQuestionDescription}
                MediaUploadType={questionDescriptionType}
                setMediaUploadType={setQuestionDescriptionType}
              />
            }
          </div>
          <div>
            <label htmlFor="uname" className="font-size-14"><b>Question Description Type</b></label><br />
            <CustomButton setData={setQuestionDescriptionType} Data={questionDescriptionType} DataValue={"text"} />
            <CustomButton setData={setQuestionDescriptionType} Data={questionDescriptionType} DataValue={"image"} />
            <CustomButton setData={setQuestionDescriptionType} Data={questionDescriptionType} DataValue={"audio"} />
            <CustomButton setData={setQuestionDescriptionType} Data={questionDescriptionType} DataValue={"video"} />
            <CustomButton setData={setQuestionDescriptionType} Data={questionDescriptionType} DataValue={"youtube"} />
            <div className="font-size-12 text-grey">
              The format you are using in the question has to be selected here.<br /><br />
            </div>
          </div>
          <div>
            <label htmlFor="uname" className="font-size-14"><b>Question Media</b></label><br />
            <input
              className="col-10 border-1 border-dark rounded-2 p-1 my-2"
              type="text"
              value={questionMedia}
              onChange={(event) => {
                setEditQuestion(false)
                setQuestionMedia(event.target.value)
              }}
              placeholder="Question Media Link"
              required
            /><br />
          </div>
          <SingleMediaUpload
            ImageUrl={questionMedia}
            setImageUrl={setQuestionMedia}
            MediaUploadType={questionMediaType}
            setMediaUploadType={setQuestionMediaType}
          />
          <div>
            <label htmlFor="uname" className="font-size-14"><b>Question Media Type</b></label><br />
            <CustomButton setData={setQuestionMediaType} Data={questionMediaType} DataValue={"image"} />
            <CustomButton setData={setQuestionMediaType} Data={questionMediaType} DataValue={"audio"} />
            <CustomButton setData={setQuestionMediaType} Data={questionMediaType} DataValue={"video"} />
            <CustomButton setData={setQuestionMediaType} Data={questionMediaType} DataValue={"youtube"} />
            <div className="font-size-12 text-grey">
              The format you are using in the question has to be selected here.<br /><br />
            </div>
          </div>

          <div>
            <label htmlFor="uname" className="font-size-14"><b>Answer Type</b></label><br />
            <CustomButton setData={setAnswerMediaType} Data={answerMediaType} DataValue={"text"} />
            <CustomButton setData={setAnswerMediaType} Data={answerMediaType} DataValue={"image"} />
            <CustomButton setData={setAnswerMediaType} Data={answerMediaType} DataValue={"audio"} />
            <CustomButton setData={setAnswerMediaType} Data={answerMediaType} DataValue={"video"} />
            <CustomButton setData={setAnswerMediaType} Data={answerMediaType} DataValue={"youtube"} />
            <div className="font-size-12 text-grey">
              The format you are using in the answer has to be selected here.
            </div>
          </div>

          <div
          //  style={{display:'flex'}}
          >
            <div className="col-lg-8">

              <label htmlFor="uname" className="font-size-14"><b>Enter Answer Option 1</b></label><br />
              <input
                className="col-10 border-1 border-dark rounded-2 p-1 my-2"
                type="text"
                value={option1}
                onChange={(event) => {
                  setEditQuestion(false)
                  setOption1(event.target.value)
                }}
                placeholder="Enter Answer Option 1"
                required
              /><br />
            </div>
            {answerMediaType != "text" &&
              <SingleMediaUpload
                ImageUrl={option1}
                setImageUrl={setOption1}
                MediaUploadType={answerMediaType}
                setMediaUploadType={setAnswerMediaType}
              />
            }
          </div>
          <div
          //  style={{display:'flex'}}
          >
            <div className="col-lg-8">

              <label htmlFor="uname" className="font-size-14"><b>Enter Answer Option 2</b></label><br />
              <input
                className="col-10 border-1 border-dark rounded-2 p-1 my-2"
                type="text"
                value={option2}
                onChange={(event) => {
                  setEditQuestion(false)
                  setOption2(event.target.value)
                }}
                placeholder="Enter Answer Option 2"
                required
              /><br />
            </div>
            {answerMediaType != "text" &&
              <SingleMediaUpload
                ImageUrl={option2}
                setImageUrl={setOption2}
                MediaUploadType={answerMediaType}
                setMediaUploadType={setAnswerMediaType}
              />
            }
          </div>
          <div
          // style={{display:'flex'}}
          >
            <div className="col-lg-8">

              <label htmlFor="uname" className="font-size-14"><b>Enter Answer Option 3</b></label><br />
              <input
                className="col-10 border-1 border-dark rounded-2 p-1 my-2"
                type="text"
                value={option3}
                onChange={(event) => {
                  setEditQuestion(false)
                  setOption3(event.target.value)
                }}
                placeholder="Enter Answer Option 3"
                required
              /><br />
            </div>
            {answerMediaType != "text" &&
              <SingleMediaUpload
                ImageUrl={option3}
                setImageUrl={setOption3}
                MediaUploadType={answerMediaType}
                setMediaUploadType={setAnswerMediaType}
              />
            }
          </div>
          <div
          //  style={{display:'flex'}}
          >
            <div className="col-lg-8">
              <label htmlFor="uname" className="font-size-14"><b>Enter Answer Option 4</b></label><br />
              <input
                className="col-10 border-1 border-dark rounded-2 p-1 my-2"
                type="text"
                value={option4}
                onChange={(event) => {
                  setEditQuestion(false)
                  setOption4(event.target.value)
                }}
                placeholder="Enter Answer Option 4"
                required
              /><br />
            </div>
            {answerMediaType != "text" &&
              <SingleMediaUpload
                ImageUrl={option4}
                setImageUrl={setOption4}
                MediaUploadType={answerMediaType}
                setMediaUploadType={setAnswerMediaType}
              />
            }
          </div>
          <div>
            <label htmlFor="uname" className="font-size-14"><b>Correct Answer</b></label><br />
            <CustomButton setData={setCorrectAnswer} Data={correctAnswer} DataValue={"option 1"} />
            <CustomButton setData={setCorrectAnswer} Data={correctAnswer} DataValue={"option 2"} />
            <CustomButton setData={setCorrectAnswer} Data={correctAnswer} DataValue={"option 3"} />
            <CustomButton setData={setCorrectAnswer} Data={correctAnswer} DataValue={"option 4"} />
          </div>
          <div>
            <label htmlFor="uname" className="font-size-14"><b>Answer Description</b></label><br />
            <textarea
              className="col-10 border-1 border-dark rounded-2 p-1 my-2"
              type="text"
              rows="5" cols="30"
              value={answerDescription}
              onChange={(event) => {
                setEditQuestion(false)
                setAnswerDescription(event.target.value)
              }}
              placeholder="Enter Answer Description"
              required
            /><br />
          </div>

          <div className="font-size-12 text-grey">
            To Insert required Media in the question or answer,<br />
            Upload above and get the link,<br />
            For Question, copy the link in the question description<br />
            For Answer (options), copy the link in the option section<br />
            Default Question and Answer Type is text<br />
            For youtube insert video url id<br />
            eg: <a href="https://www.youtube.com/watch?v=Yu2Pvc1ObRw">https://www.youtube.com/watch?v=Yu2Pvc1ObRw</a> in this link
            <br />
            Yu2Pvc1ObRw is the video id<br />
            For mp4 and mp3 format video and audio just paste the link and select type as required.<br />
            Image on our server are converted to webp format just paste the link and select the type as required<br />
          </div>
          {!editQuestion ?
            <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
              <div className="sign-in-button-1 col-6"
                onClick={() => {
                  setAllQuestionData({
                    ...AllQuestionData,
                    [keyValue]: {
                      "question_number": oneQuestionData.question_number,
                      "question": question,
                      "question_description": questionDescription,
                      "question_description_type": questionDescriptionType,
                      "question_media": questionMedia,
                      "question_media_type": questionMediaType,
                      "option_1": option1,
                      "option_2": option2,
                      "option_3": option3,
                      "option_4": option4,
                      "answer_description": answerDescription,
                      "correct_answer": correctAnswer,
                      "answer_media_type": answerMediaType,
                    }
                  }
                  )
                  setEditQuestion(true)
                }}
              >
                Edit Question
              </div>
            </div>
            :
            <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
              <div className="sign-in-button-1 col-6"
                onClick={() => {
                  setEditQuestion(false)
                  PostExam()
                }}
              >
                Update Database
              </div>
            </div>
          }
        </div>
      </div>
      <div className="col-lg-4" >
        <div style={{ minHeight: "50vh" }}></div>
        <div>
          <label htmlFor="uname" className="font-size-14"><b>Upload Media</b></label><br />
          <div onClick={() => {
            setCopytoClipBoard(false)
          }}>
            <SingleMediaUpload
              ImageUrl={MediaUpload}
              setImageUrl={setMediaUpload}
              MediaUploadType={MediaUploadType}
              setMediaUploadType={setMediaUploadType}
            />
          </div>
          <div className="font-size-12 text-grey">{MediaUpload}</div>
          <div style={{ cursor: 'pointer' }}
            onClick={() => {
              navigator.clipboard.writeText(MediaUpload).then(function () {
                setCopytoClipBoard(true)
              }, function () {
                console.log("failed")
              });
            }}
          >Copy to clipboard</div>
          {CopyToClipBoard &&
            <div className="font-size-12 text-grey">Copied to ClipBoard</div>
          }
          <div>
            <label htmlFor="uname" className="font-size-14"><b>Upload Media Type</b></label><br />
            <CustomButton setData={setMediaUploadType} Data={MediaUploadType} DataValue={"image"} />
            <CustomButton setData={setMediaUploadType} Data={MediaUploadType} DataValue={"audio"} />
            <CustomButton setData={setMediaUploadType} Data={MediaUploadType} DataValue={"video"} />
            <div className="font-size-12 text-grey">
              The format you are using in the question has to be selected here.<br /><br />
            </div>
          </div>
        </div>


      </div>
    </div>
  )
}