import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, unstable_HistoryRouter, useLocation } from 'react-router-dom';
import { PostGETApi, PostGETApiAuth } from '../../constants/api';
import { NotFound } from '../../NotFound';
import { login, logout } from '../../redux/action';
import store from '../../redux/store';
import SideBar from '../SideBar';
import FirstScreenExam from './FirstScreen';
import AllExamList1 from '../../constants/demoExam.json'
import axios from 'axios';

export default function StartDemoExamAdmin(props) {
  const dispatch = useDispatch()
  const location = useLocation()
  const [ExamData, setAllExamList] = React.useState({});
  const [ExamFetch, setExamFetch] = React.useState(false);
  const [NotFoundData, setNotFound] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const [choose, setChoose] = React.useState(0)
  const [AnswerArray, setAnswerArray] = React.useState([]);
  const [ResultData, setResultData] = React.useState({});
  const fetchExam = (slug) => {
    // setExamFetch(true)
    setNotFound(false)
    const id = store.getState().LoginState.userID
    console.log(slug)
    axios({
      method: "GET",
        url: `https://ubttest.skysewa.com/wp-json/wp/v2/demo_exam`,
      params: {
        slug: slug
      }
    })
      .then((res) => {
        console.log(slug,res.data)
        // if (res.data.filter(item => item.slug == slug).length !== 0) {
        //   setAllExamList(AllExamList1.filter(item => item.slug == slug)[0])
        //   console.log(slug, AllExamList1.filter(item => item.slug == slug)[0])
        //   setReload(false)
        // }
        if (res.data[0].meta.exam_question_block !== null)
          setAllExamList(res.data[0])
        else {
          setNotFound(true)
        }

        setExamFetch(true)
      }
      )

    // PostGETApiAuth({ slug: slug }, `exam-set`)
    //   .then((res) => {
    //     if (res.data.length == 1) {
    //       PostGETApiAuth({
    //         "filter[meta_query][1][key]": "exam_id_results",
    //         "filter[meta_query][1][value][0]": res.data[0].id,
    //         "filter[meta_query][1][compare]": "=",
    //         "filter[meta_query][relation]": "AND",
    //         "filter[meta_query][2][key]": "student_id_results",
    //         "filter[meta_query][2][value][0]": id,
    //         "filter[meta_query][2][compare]": "=",
    //       }, "exam_results")
    //         .then((response) => {
    //           if (response.data.length !== 0) {
    //             setChoose(2)
    //             setAnswerArray(JSON.parse(response.data[0].meta.exam_answers_array_results))
    //             setResultData(response.data[0])
    //           }

    // if (res.data[0].meta.exam_question_block !== null)
    //   setAllExamList(res.data[0])
    // else {
    //   setNotFound(true)
    // }

    //       setExamFetch(true)
    //     })
    //     .finally(() => {
    //       setReload(false)
    //     })
    // }
    // else {
    //   setNotFound(true)
    //   setExamFetch(true)
    //   setReload(false)
    // }
    // })
    // .catch(err => {
    //   console.log(err)
    //   console.log(err.response.data)
    // })

  }
  const [finishStatus, setfinishStatus] = React.useState(false);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Do you want to go back ?")) {
        setfinishStatus(true)
        // your logic
        props.history.push("/");
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false)
      }
    }
  }

  useEffect(() => {
    fetchExam(location.pathname.slice(11))
    window.addEventListener("beforeunload", alertUser);

    return () => {
      window.addEventListener("beforeunload", (event) => {
        event.returnValue = true;
      });
    };
  }, [])
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  return (
    <div
      style={{ backgroundColor: "#fff" }}
    >
      <div className="row">
        <div className="col-md-1">
        </div>
        <div className="col-md-10" style={{ padding: "10px" }}>
          {ExamFetch &&
            <>
              {NotFoundData ?
                <NotFound /> :

                <FirstScreenExam
                  ExamData={ExamData}
                  setReload={setReload}
                  choose={choose}
                  setChoose={setChoose}
                  AnswerArray={AnswerArray}
                  setAnswerArray={setAnswerArray}
                  ResultData={ResultData} setResultData={setResultData}
                />
              }
            </>
          }
        </div>
      </div>
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}