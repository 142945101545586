import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { login, logout } from '../../redux/action';
import SideBar from '../SideBar';
const imageHeight = "200px"
export default function Home() {
  const dispatch = useDispatch()
  const [A1, setA1] = React.useState(false)
  const data = [
    {
      slug: "ubt",
      image: "http://skysewa.com/wp-content/uploads/2023/01/sky.png"
    },
    {
      slug: "cbt-chemical",
      image: "http://ubttest.skysewa.com/wp-content/uploads/2023/01/chemical-cbt.png"
    },
    {
      slug: "cbt-electronics-electricity",
      image: "http://ubttest.skysewa.com/wp-content/uploads/2023/01/electricity-cbt.png"
    },
    {
      slug: "cbt-food-related",
      image: "http://ubttest.skysewa.com/wp-content/uploads/2023/01/food-cbt.png"
    },
    {
      slug: "cbt-metal",
      image: "http://ubttest.skysewa.com/wp-content/uploads/2023/01/metal-cbt.png"
    },
    {
      slug: "cbt-pulp-paper-wood",
      image: "http://ubttest.skysewa.com/wp-content/uploads/2023/01/paper-cbt.png"
    },
    {
      slug: "cbt-textile-sewing",
      image: "http://ubttest.skysewa.com/wp-content/uploads/2023/01/textile-cbt.png"
    },
  
  ]
  const Block = ({ slug, image }) => {
    return (
      <div className="col-lg-3 col-6" >
        <Link style={{
          textDecorationLine: 'none',
          // color: "#000",
        }}
          to={`/exam-type/${slug}`}
        >
          <div style={{
            backgroundColor: "#ffffff",
            display: 'flex',
            alignContent: 'center', alignItems: 'center',
            padding: "5px", margin: "10px 0px", border: "1px solid black",
            width: "220px", height: "220px",
            borderRadius: "5px"
          }}
            className="home-box center"
          >
            <img
              style={{
                backgroundColor:"#fff",border: "1px solid black",
                width: "220px", height: "220px",
                borderRadius: "5px"
              }}
              src={image}
            />
          </div>
        </Link>

      </div>
    )
  }
  return (
    <div
      style={{ backgroundColor: "#fff" }}
      className="row"
    >
      <div className="col-md-2 ">
        <SideBar DashboardList={6} />
      </div>
      <div className="col-md-9 ">

        <div className="row">
          {data.map((item, index) => (
            <Block slug={item.slug} image={item.image} />
          ))}
          {/* <Block slug={data[1].slug} image={data[1].image} />
          <Block slug={data[2].slug} image={data[2].image} />
          <Block slug={data[3].slug} image={data[3].image} />
          <Block slug={data[4].slug} image={data[4].image} />
          <Block slug={data[5].slug} image={data[5].image} />
          <Block slug={data[6].slug} image={data[6].image} />
          <Block slug={data[7].slug} image={data[7].image} /> */}

        </div>

      </div>
    </div >
  )
}