import axios from 'axios';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PostGETApiAuth, PostPOSTApi, PostPUTApi } from '../../constants/api';
import store from '../../redux/store';
import SideBar from '../SideBar';
import SingleMediaUpload from './AllTypeMedia';
import examData from './examData.json'
import QuestionComponent from './QuestionForm';
import SinglePicturesUpload from './SingleArray';
export default function ExamFormComponent(props) {
  const {
    ExamFormData,
    setAlertcomp,
    setAlertShow,
    setReload
  } = props;
  const [ExamId, setExamId] = React.useState(ExamFormData.id);
  const [ExamStatus, setExamStatus] = React.useState(ExamFormData.status);
  const [title, setTitle] = React.useState(ExamFormData.title.rendered);
  const [content, setContent] = React.useState(ExamFormData.meta.exam_details);
  const [featuredImage, setFeaturedImage] = React.useState(ExamFormData.meta.featured_image);
  const [examTime, setExamTime] = React.useState(ExamFormData.meta.exam_time);
  const [Available, setAvailable] = React.useState(ExamFormData.meta.available_unavailable);
  const [ExamType, setExamType] = React.useState(ExamFormData.meta.exam_type);
  const [QuestionsData, setQuestionsData] = React.useState(ExamFormData.meta.exam_question_block);
  const [QuestionsEditMode, setQuestionsEditMode] = React.useState(true);
  const [QuestionsEditData, setQuestionsEditData] = React.useState(ExamFormData.meta.exam_question_block["item-0"]);
  const [QuestionsEditKey, setQuestionsEditKey] = React.useState("item-0");
  const [choose, setChoose] = React.useState("0");
  const PostExam = () => {
    setReload(true)
    const data = {
      title: title,
      // content: content,
      status: ExamStatus,
      "meta": {
        "exam_details": content,
        "featured_image": featuredImage,
        "exam_time": examTime,
        "exam_type": ExamType,
        "available_unavailable": Available,
        "exam_question_block": QuestionsData
      }
    }
    const token = store.getState().LoginState.token
    if (ExamId == "")
      // PostPOSTApi(data, "exam-set")
      axios({
        method: "POST",
        url: `https://ubttest.skysewa.com/wp-json/wp/v2/demo_exam`,
        headers: {
          "Authorization": `Basic ${token}`,
        },
        data: data
      })
        .then((res) => {
          console.log(res.data)
          setReload(false)
          setExamId(res.data.id)
          setChoose("1")
        }).catch(err => {
          console.log(err)
          console.log(err.response.data)
          setReload(false)
        })
    else
      // PostPUTApi(data, "exam-set", ExamId)
      axios({
        method: "PUT",
        url: `https://ubttest.skysewa.com/wp-json/wp/v2/demo_exam/${ExamId}`,
        headers: {
          "Authorization": `Basic ${token}`,
        },
        data: data
      })
        .then((res) => {
          setReload(false)
        }).catch(err => {
          console.log(err)
          setReload(false)
        })
  }
  const ExamName = [
    "UBT",
    "CBT CHEMICAL",
    "CBT ELECTRONIC ELECTRCITY",
    "CBT FOOD RELATED",
    "CBT MACHINERY,MOLDING",
    "CBT METAL",
    "CBT PULP PAPER WOOD",
    "CBT RUBBER",
    "CBT TEXTILE SEWING"
  ]
  return (
    <div className="p-5">
      <h1> {ExamId == "" ? "Add New Exam" : `Edit ${title}`}</h1>
      {choose == "0" &&
        <div>
          <div>
            <label htmlFor="uname" className="font-size-14"><b>Exam Title</b></label><br />
            <input
              className="col-lg-6 col-md-12 col-sm-12 col-xs-12 border-1 border-dark rounded-2 p-2 my-2"
              type="text"
              value={title}
              onChange={(event) => { setTitle(event.target.value) }}
              placeholder="Enter Exam title"
              required
            /><br />
          </div>

          <div>
            <label htmlFor="uname" className="font-size-14"><b>Exam Description</b></label><br />
            <textarea
              className="col-lg-6 col-md-12 col-sm-12 col-xs-12 border-1 border-dark rounded-2 p-2 my-2"
              type="text"
              rows="5" cols="30"
              value={content}
              onChange={(event) => { setContent(event.target.value) }}
              placeholder="Enter Exam Description"
              required
            /><br />
          </div>

          <div>
            <h6><b>Project Logo Image</b></h6><br />
            <input
              className="col-lg-6 col-md-12 col-sm-12 col-xs-12 border-1 border-dark rounded-2 p-2 my-2"
              type="text"
              value={featuredImage}
              onChange={(event) => { setFeaturedImage(event.target.value) }}
              placeholder="Enter Exam Image"
              required
            /><br />
            <SinglePicturesUpload
              ImageUrl={featuredImage}
              setImageUrl={setFeaturedImage}
            />
          </div>

          <div>
            <label htmlFor="uname" className="font-size-14"><b>Exam Time</b></label><br />
            <input
              className="col-lg-6 col-md-12 col-sm-12 col-xs-12 border-1 border-dark rounded-2 p-2 my-2"
              type="text"
              value={examTime}
              onChange={(event) => { setExamTime(event.target.value) }}
              placeholder="Enter Exam Time in minutes"
              required
            /><br />
            <div className="font-size-12 text-grey">eg. 30 is equal to 30 minutes</div>
          </div>

          <div>
            <label htmlFor="uname" className="font-size-14"><b>Exam Available Now</b></label><br />
            <button className="border-0 bg-transparent m-1 "
              onClick={() => {
                setAvailable("Available")
              }}
            >
              <div className={Available == "Available" ? "font-size-12 background-grey font-weight-700 border border-1 border-dark py-2 px-3 rounded-1" : "font-size-12 border border-1 py-2 px-3 rounded-1"}>
                Available
              </div>
            </button>
            <button className="border-0 bg-transparent m-1 "
              onClick={() => { setAvailable("Unavailable") }}
            >
              <div className={Available == "Unavailable" ? "font-size-12 background-grey font-weight-700 border border-1 border-dark py-2 px-3 rounded-1" : "font-size-12 border border-1 py-2 px-3 rounded-1"}>
                Unavailable
              </div>
            </button>
          </div>
          {/* <div>
            <label htmlFor="uname" className="font-size-14"><b>Exam Type</b></label><br />
            {ExamName.map((item, index) => (
              <>
              <button className="border-0 bg-transparent m-1 col-4"
                onClick={() => {
                  setExamType(item)
                }}
              >
                <div className={ExamType == item ? "font-size-12 background-grey font-weight-700 border border-1 border-dark py-2 px-3 rounded-1" : "font-size-12 border border-1 py-2 px-3 rounded-1"}>
                  {item}
                </div>
              </button>
              </>
            ))}
          </div> */}
          <button className="sign-in-button-2 p-2 m-2"
            onClick={() => {
              PostExam()
            }}
          >
            Save Details and Set Questions
          </button>
          <button className="sign-in-button-1 p-2 m-2"
            onClick={() => {
              setChoose("1")
            }}
          >
            Edit Questions
          </button>

        </div>
      }
      {choose == "1" &&
        <div>
          <div>
            <button className="sign-in-button-1 p-2 m-2"
              onClick={() => {
                setChoose("0")
              }}
            >
              Edit Exam Details
            </button>
          </div>

          <label htmlFor="uname" className="font-size-14"><b>Questions</b></label><br />
          <div className="row">
            <div className="col-md-6 row" >
              {Object.keys(QuestionsData).map((item, index) => {
                if (index < 20)
                  return (
                    <div key={index} className="col-2 m-1 p-2 center"
                      style={{
                        backgroundColor: QuestionsEditKey == item ? "#fad4d4" : "#fafad4",
                        fontWeight: QuestionsEditKey == item ? "bold" : "normal",
                        border: "2px solid black",
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setQuestionsEditData(QuestionsData[item])
                        setQuestionsEditKey(item)
                        setQuestionsEditMode(false)
                        setTimeout(() => {
                          setQuestionsEditMode(true)
                        }, 10)
                      }}
                    >
                      {QuestionsData[item].question_number}
                    </div>
                  )
              })}
            </div>
            <div className="col-md-6 row">
              {Object.keys(QuestionsData).map((item, index) => {
                if (index >= 20)
                  return (
                    <div key={index} className="col-2 m-1 p-2 center"
                      style={{
                        backgroundColor: QuestionsEditKey == item ? "#fad4d4" : "#fafad4",
                        fontWeight: QuestionsEditKey == item ? "bold" : "normal",
                        border: "2px solid black",
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setQuestionsEditData(QuestionsData[item])
                        setQuestionsEditKey(item)
                        setQuestionsEditMode(false)
                        setTimeout(() => {
                          setQuestionsEditMode(true)
                        }, 10)
                      }}
                    >
                      {QuestionsData[item].question_number}
                    </div>
                  )
              })}
            </div>
          </div>
          {QuestionsEditMode &&
            <div className="col-12">
              <QuestionComponent
                keyValue={QuestionsEditKey}
                oneQuestionData={QuestionsEditData}
                AllQuestionData={QuestionsData}
                setAllQuestionData={setQuestionsData}
                PostExam={PostExam}
              />
            </div>
          }
          {/* <div className="row">
            {Object.keys(QuestionsData).map((item, index) =>
            (
              <div key={index} className="col-5 m-3" style={{ backgroundColor: "#fcebeb", width: "100%" }}>
                {JSON.stringify(item)}
                {JSON.stringify(QuestionsData[item])}
              </div>
            )
            )}
          </div> */}
        </div>
      }
    </div>
  )
}