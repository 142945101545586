import axios from 'axios';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PostGETApiAuth } from '../../constants/api';
import SideBar from '../SideBar';
// import AllExamList1 from '../../constants/demoExam.json'
export default function DemoExamAdmin() {
  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const [AllExamList1, setAllExamList1] = React.useState([]);
 
  const [ExamFormShow, setExamFormShow] = React.useState(false);
  const [reload, setReload] = React.useState(false)
  const LoginState = useSelector((state) => state.LoginState);

  const fetchExam = (key, value, setAllExamList, perPage) => {
    setReload(true)
    // PostGETApiAuth({
    // }, 'exam-set')
    axios({
      method: "GET",
      url: `https://ubttest.skysewa.com/wp-json/wp/v2/demo_exam?order=asc&per_page=30`,
    })
      .then((res) => {
        setAllExamList(res.data)
        setReload(false)
        console.log(res.data.length)
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
      })
  }
  useEffect(() => {
    fetchExam("exam_type", "UBT", setAllExamList1, { per_page: 8, order: "asc" })
     }, [])
 
  const ExamBlock = ({ item }) => {
    return (
      <div style={{ padding: "5px", margin: "10px 0px", border: "1px solid black", height: "250px" }}>
        {item.meta.featured_image !== "" &&
          <>
            <img
              src={item.meta.featured_image}
              style={{
                objectFit: 'contain',
                height: "150px",
              }}
              className="col-12"
            />
          </>
        }
        <br />
        {item.title.rendered}
        {item.meta.exam_question_block !== null &&
          <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
            {item.meta.available_unavailable == "Available" ?
              <Link className="sign-in-button-1 col-8 Link" style={{ color: "#fff" }}
                to={`/demo-exam/${item.slug}`}
              >
                Start Exam
              </Link>
              :
              <div className="sign-in-button-1 col-8 Link" style={{ color: "#000", backgroundColor: "#fff" }}>
                Exam Unavailable
              </div>
            }
          </div>
        }
      </div>
    )

  }
  const userRole = localStorage.getItem('userRole');
  const HeaderBlock = ({ title, slug }) => {
    return (
      <div className="row">
        <div style={{ width: "70%" }}>
          <h2>{title}</h2>
        </div>

        <Link style={{ textDecorationLine: 'none', color: "#000", width: "30%", textAlign: 'right', alignItems: 'center' }}
          to={`/exam-type/${slug}`}
        >
          See More &nbsp;&nbsp;
          <i className="fa fa-chevron-right" />
          &nbsp;&nbsp;&nbsp;
        </Link>

      </div>
    )

  }
  return (
    <div>
      <div
        style={{ backgroundColor: "#fff" }}
        className="row"
      >
        <div className="col-md-2 ">
          <SideBar DashboardList={9} />
        </div>
        <div className="col-md-10 ">

          <div className="row" style={{ margin: "10px" }}>
            {AllExamList1.length !== 0 && <HeaderBlock title={"Demo Exams"} slug={"ubt"} />}
            {AllExamList1.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-6 center">
                <ExamBlock item={item} />
              </div>
            ))}
          </div>
        </div>
        {reload &&
          <div className="modal" style={{ padding: "50vw" }}>
            <div className="dot-pulse"></div>
          </div>
        }
      </div>
    </div>
  )
}