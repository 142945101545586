import React, { useEffect } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";


import store from './redux/store';
import { getUser, Location } from './redux/action';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { NotFound } from './NotFound';
import Home from './Path/Home';
import Profile from './Path/Profile';
import Login from './Path/Login';
import Register from './Path/Login/SignUp';
import Results from './Path/Results';
import Exam from './Path/Exam';
import Header from './Path/Header';
import AddExam from './Path/AddExam';
import ManageStudents from './Path/ManageStudents';
import StartExam from './Path/StartExam';
import DetailedResult from './Path/DetailsResult';
import ExamType from './Path/ExamTypes';
import DemoExam from './Path/DemoExams';
import StartDemoExam from './Path/StartExam copy';
import AddExamDemo from './Path/AddExamDemo';
import StartDemoExamAdmin from './Path/StartExamDemo';
import DemoExamAdmin from './Path/DemoExamsAdmin';
import AllStudents from './Path/Students';
import NewStudent from './Path/Students/StudentAddNew';
import StudentDetails from './Path/Students/StudentDetails';

export default class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <AppMain />
      </Provider>
    );
  }
}
function AppMain() {
  const [loginState, setLoginState] = React.useState(true)
  const dispatch = useDispatch()
  const LoginUser = useSelector((state) => state.LoginState);

  useEffect(() => {
    dispatch(getUser())
    setTimeout(() => {
      setLoginState(LoginUser.isLoading)
    }, 100)
  }, [])
  return (
    <div style={{overflowX:'hidden'}}>
      {!LoginUser.isLoading &&
        <Router>
          <div>
            {LoginUser.token !== null &&
              <Header />
            }
            <Routes>
              <Route path="/" element={LoginUser.token == null ? <Navigate to="/login" /> : <Home />} />
              <Route path="/exam" element={LoginUser.token == null ? <Navigate to="/login" /> : <Exam />} />
              {/* <Route path="/demo-exams" element={LoginUser.token == null ? <Navigate to="/login" /> : <DemoExam />} /> */}
              <Route path="/exam-type/:slug" element={LoginUser.token == null ? <Navigate to="/login" /> : <ExamType />} />
              {/* <Route path="/" element={<Exam />} /> */}
              <Route path="/login" element={LoginUser.token == null ? <Login /> : <Navigate to="/" />} />
              <Route path="/register" element={LoginUser.token == null ? <Register /> : <Navigate to="/" />} />
               
              <Route path="/profile" element={LoginUser.token == null ? <Navigate to="/login" /> : <Profile />} />
              <Route path="/add-exam" element={LoginUser.userRole !== "author" ? <Navigate to="/login" /> : <AddExam />} />
              <Route path="/add-exam-demo" element={LoginUser.userRole !== "author" ? <Navigate to="/login" /> : <AddExamDemo />} />

              <Route path="/all-students" element={LoginUser.userRole !== "author" ? <Navigate to="/login" /> : <AllStudents />} />
              <Route path="/new-student" element={LoginUser.userRole !== "author" ? <Navigate to="/login" /> : <NewStudent />} />
              <Route path="/student/:id" element={LoginUser.userRole !== "author" ? <Navigate to="/login" /> : <StudentDetails />} />

              <Route path="/manage-students" element={LoginUser.userRole !== "author" ? <Navigate to="/login" /> : <ManageStudents />} />
              <Route path="/demo-exam/:slug" element={LoginUser.token == null ? <Navigate to="/login" /> : <StartDemoExamAdmin />} />
              <Route path="/exam-demo" element={LoginUser.token == null ? <Navigate to="/login" /> : <DemoExamAdmin />} />
              
              <Route exact path="/results" element={LoginUser.token == null ? <Navigate to="/login" /> : <Results />} />
              <Route exact path="/exam/:slug" element={LoginUser.token == null ? <Navigate to="/login" /> : <StartExam />} />
              {/* <Route exact path="/demo-exam/:slug" element={LoginUser.token == null ? <Navigate to="/login" /> : <StartDemoExam />} /> */}
              
              <Route exact path="/result/:slug" element={LoginUser.token == null ? <Navigate to="/login" /> : <DetailedResult />} />

              <Route path="/*" element={<NotFound />} />

            </Routes>
          </div>
          {/* <Route exact path="/exam"><Users /></Route> */}
          {/* <Route exact path="/login"><Projects /></Route> */}
        </Router>

      }
    </div>
  )

}