import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PostGETApiAuth } from '../../constants/api';
import SideBar from '../SideBar';
import SingleUserData from './UserData';

export default function ManageStudents() {
  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const [AllUserList, setAllUserList] = React.useState([]);
  const [choose, setChoose] = React.useState(0)
  const [userData, setUserData] = React.useState({})
  const [search, setSearch] = React.useState("")
  const [ListT, setListT] = React.useState(true)
  const [reload, setReload] = React.useState(false)
  const fetchExam = (params) => {
    setReload(true)
    PostGETApiAuth(params, 'users_data')
      .then((res) => {
        setAllUserList(res.data)
        console.log(res.data)
        setReload(false)
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
      })
  }
  useEffect(() => {
    fetchExam({
      status: "",
      "filter[meta_query][1][key]": "user_role_user",
      "filter[meta_query][1][value][0]": "student",
      "filter[meta_query][1][compare]": "=",
      per_page
    })
  }, [])
  const AlertComponent = () => {
    return (
      <div className="modal"
        onClick={() => {
          setAlertShow(false)
        }}
      >
        <div>
          <div className="row " style={{ minHeight: "10vh", marginTop: "10vh" }}>
            <div className="col-lg-4 col-1"></div>
            <div className="col-lg-4 col-10 pb-5  bg-white  shadow">
              <div className="p-5">
                <div dangerouslySetInnerHTML={{ __html: Alertcomp }}></div>
              </div>
              <div style={{ display: 'flex', cursor: 'pointer' }} className="center">
                <div className="sign-in-button-1 col-8">
                  OK
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div
      style={{ backgroundColor: "#fff" }}
      className="row"
    >
      <div className="col-md-2 ">
        <SideBar DashboardList={5} />
      </div>
      {ListT ?
        <div className="col-md-10 ">
          <div className="row m-1">
            <div className={choose == 0 ? "col-3 active-bar" : "col-3 passive-bar"}
              onClick={() => {
                setChoose(0)
                fetchExam({
                  status: "any",
                  "filter[meta_query][1][key]": "user_role_user",
                  "filter[meta_query][1][value][0]": "student",
                  "filter[meta_query][1][compare]": "=",
                  per_page: 100
                })
              }}
            >Verified Students</div>
            <div className={choose == 1 ? "col-3 active-bar" : "col-3 passive-bar"}
              onClick={() => {
                setChoose(1)
                fetchExam({
                  status: "any",
                  "filter[meta_query][1][key]": "user_role_user",
                  "filter[meta_query][1][value][0]": "subscriber",
                  "filter[meta_query][1][compare]": "=",
                  "filter[meta_query][relation]": "AND",
                  per_page: 100
                })

              }}
            >Un verified Students</div>
            <div className={choose == 2 ? "col-3 active-bar" : "col-3 passive-bar"}
              onClick={() => {
                setChoose(2)
                fetchExam({
                  status: "any",
                  "filter[meta_query][1][key]": "verification_status_user",
                  "filter[meta_query][1][value][0]": "pending",
                  "filter[meta_query][1][compare]": "=",
                  per_page: 100
                })

              }}
            >Pending Students</div>
            <div className={choose == 3 ? "col-3 active-bar" : "col-3 passive-bar"}
              onClick={() => {
                setChoose(3)
                fetchExam({
                  status: "any",
                  per_page: 100
                })
              }}
            >All Students</div>
          </div>
          {/* {JSON.stringify(AllUserList)} */}
          <div>
            Search:
            <input
              className="col-lg-3 col-md-12 col-sm-12 col-xs-12 border-1 border-dark rounded-2 p-2 my-2"
              type="text"
              value={search}
              onChange={(event) => { setSearch(event.target.value) }}
              placeholder="Search"
              required
            />
            <span style={{ width: "150px", color: "#fff", margin: "0 10px ", padding: "7px", backgroundColor: "#000", borderRadius: "10px" }}
              onClick={() => {
                fetchExam({
                  status: "any",
                  search: search,
                  per_page: 100
                })
              }}
            >
              Search
            </span>
          </div>
          <div className="row" style={{ margin: "10px" }}>

            {AllUserList.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-6 " style={{ cursor: 'pointer' }}
                onClick={() => {
                  setUserData(item)
                  setListT(false)
                }}
              >
                <div style={{ padding: "10px", margin: "10px 0px", border: "1px solid black", height: "300px" }}>
                  <div className="center">
                    {item.meta.user_profile_picture_user === "" ?
                      <img src={`https://ubtvirtualtest.com/wp-content/uploads/2022/05/User_font_awesome.png`}
                        height="70px"
                      />
                      :
                      <img src={`${item.meta.user_profile_picture_user}`} className={"height-width-150 border border-dark"}
                        style={{ height: "70px", width: "70px" }}
                      />
                    }
                  </div>
                  <br />
                  <br />
                  <b>UserName:</b> {item.meta.user_name}
                  {item.meta.user_role_user == "student" &&
                    <div >
                      <div><b>UserRole:</b> Student<br /></div>
                      <div><b>Subscription- <br />Started: {item.meta.user_subscription_start_user}</b></div>
                      <div><b>Ends In: {item.meta.user_subscription_end_user}</b></div>
                      <div style={{ color: "#00f" }}><b>Status: Verified</b></div>
                    </div>
                  }

                  {item.meta.user_role_user == "subscriber" &&
                    <div>
                      <b>UserRole:</b> Subscriber<br />
                      <div><b>Status:{item.meta.user_role_user}
                        {item.meta.verification_status_user == "" ?
                          <span style={{ color: "#f00" }}> Unverified</span> :
                          <span style={{ color: "#000" }}> Pending</span>
                        }</b></div>
                    </div>
                  }
                </div>
              </div>
            ))}

          </div>
        </div>
        :
        <div className="col-md-10 ">
          <div className="p-3" onClick={() => {
            setListT(true)
          }}>
            <i className="fa fa-arrow-left" /> Go Back
          </div>
          <SingleUserData
            userData={userData}
            setReload={setReload}
            fetchExam={fetchExam}
            setListT={setListT}
            setChoose={setChoose}
          />
        </div>
      }

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}