import React, { useEffect } from 'react';
import { apiUrl, ImageURL, MediaUpload } from '../../constants/api';
import Resizer from "react-image-file-resizer";

export default function SinglePicturesUpload(props) {
  const {
    ImageUrl,
    setImageUrl,
  } = props;
  const fileRefSingle = React.createRef();
  const [Loading, setLoading] = React.useState(false)

  const MediaUploadSingleCall = (file, i) => {
    if (!file) return;
    var fileInput = "";
    if (file) {
      fileInput = file.type;
    }
    console.log("1", file)
    if (fileInput.includes("image")) {
      try {
        Resizer.imageFileResizer(
          file,
          1000,
          1000,
          "JPEG",
          40,
          0,
          (uri) => {
            // this.setState({ newImage: uri });
            console.log("2", uri)

            MediaUpload(uri)
              .then(res => res.json())
              .then(data => {
                setImageUrl(data.source_url)
                setTimeout(() => {
                  setLoading(false)
                }, 1000);
              })
              .catch(err => { console.log("error", err.message); })
              .finally(() => { })
          },
          "file",
          200,
          200
        );
      } catch (err) {
        console.log(err);
      }
    }
    else {
      MediaUpload(file)
        .then(res => res.json())
        .then(data => {
          setImageUrl(data.source_url)
          setTimeout(() => {
            setLoading(false)
          }, 1000);
        })
        .catch(err => { console.log("error", err.message); })
        .finally(() => { })
    }
  }

  return (
    <div>
      {Loading &&
        <div className="spinner-border"></div>
      }
      <div className="col-lg-6 col-md-6">
        <div className="row">
          {ImageUrl !== "" ?
            <div className="col-3 my-2">
              <div className=" ">
                <button type="button" onClick={() => { fileRefSingle.current.click() }}
                  className="bg-transparent border-0 p-1 "
                >
                  <img src={`${ImageUrl}`} className={" border border-dark"}
                    height="100px"
                  />
                </button>
              </div>
              <button onClick={() => {
                setImageUrl("")
              }}
                className="bg-transparent border-0 p-1 "
              ><u>Clear</u></button>
            </div>
            :
            <div className="col-3">
              <button type="button"
                onClick={() => {
                  fileRefSingle.current.click()
                }}
                className="bg-transparent border-0 p-1 "
              >
                <div className="border height-width-150 border-dark m-1 p-4 font-size-12">
                  <i className="fa fa-camera font-size-18"></i>
                  <div>Add</div>
                </div>
              </button>
            </div>
          }
        </div>
      </div>
      <div style={{ display: 'none' }}>
        <input type="file" accept="image/*" name="image" id="file" ref={fileRefSingle}
          onChange={(event) => {
            if (event.target.files.length !== 0) {
              setLoading(true)
              MediaUploadSingleCall(event.target.files[0])
            }
          }}
        />
      </div>


    </div>
  )
}