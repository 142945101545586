import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PostGETApiAuth } from '../../constants/api';
import SideBar from '../SideBar';
import examData from './examData.json'
import ExamFormComponent from './ExamForm';
export default function AddExam() {
  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const [AllExamList, setAllExamList] = React.useState([]);
  const [ExamFormShow, setExamFormShow] = React.useState(false);
  const [ExamFormData, setExamFormData] = React.useState(examData);
  const [reload, setReload] = React.useState(false)
  const [pagesLength, setPagesLength] = React.useState("1");
  const [pages, setPages] = React.useState(1);

  const fetchExam = (params) => {
    setReload(true)
    PostGETApiAuth(params, 'exam-set')
      .then((res) => {
        setAllExamList(res.data)
        setReload(false)
        setPagesLength(res.headers["x-wp-totalpages"])
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
        setReload(false)
      })
  }
  useEffect(() => {
    fetchExam({ status: "any", per_page: 48 })
  }, [])
  const AlertComponent = () => {
    return (
      <div className="modal"
        onClick={() => {
          setAlertShow(false)
        }}
      >
        <div>
          <div className="row " style={{ minHeight: "10vh", marginTop: "10vh" }}>
            <div className="col-lg-4 col-1"></div>
            <div className="col-lg-4 col-10 pb-5  bg-white  shadow">
              <div className="p-5">
                <div dangerouslySetInnerHTML={{ __html: Alertcomp }}></div>
              </div>
              <div style={{ display: 'flex', cursor: 'pointer' }} className="center">
                <div className="sign-in-button-1 col-8">
                  OK
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  let myarray = []
  for (let i = 0; i < pagesLength; i++)
    myarray.push(
      <div key={i} style={{ margin: 3, width: "20px", display: "inline-block" }}>
        <div onClick={() => {
          setPages(i + 1)
          setAllExamList([])
          fetchExam({ page: i + 1, per_page: 48, order: "desc" })
          // GetAllProjects({ per_page: 100, page: i + 1 })
        }}
          style={{ border: "0", padding: 7, backgroundColor: pages == i + 1 ? "#0d02a6" : "#f00", color: "#fff", borderRadius: 5 }}
        >

          <span style={{ color: "#fff" }}>{i + 1}</span>
        </div>
      </div>
    )


  return (
    <div
      style={{ backgroundColor: "#fff", overflowX: 'hidden' }}
      className="row"
    >
      <div className="col-md-2 ">
        <SideBar DashboardList={4} />
      </div>
      <div className="col-md-9 ">
        {!ExamFormShow ?
          <div>
            <div className="p-3">
              <button className="sign-in-button-1 col-lg-3 col-6"
                onClick={() => {
                  setExamFormData(examData)
                  setExamFormShow(true)
                }}
              >
                Add New Exam
              </button>
            </div>
            <div className="row">
              {AllExamList.map((item, index) => (
                <div key={index} className="col-lg-3 col-md-4 col-6 center">
                  <div style={{ padding: "5px", margin: "15px 5px", border: "1px solid black", height: "250px" }}>
                    {item.meta.featured_image !== "" &&
                      <img src={item.meta.featured_image}
                        style={{
                          objectFit: 'contain',
                          height: "150px"
                        }}
                      />
                    }
                    <br />
                    {item.title.rendered}
                    <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                      <div className="sign-in-button-1 col-8"
                        onClick={() => {
                          if (item.meta.exam_question_block == null) {
                            const examDataCache = {
                              ...item,
                              meta: {
                                ...item.meta,
                                exam_question_block: examData.meta.exam_question_block
                              }
                            }
                            setExamFormData(examDataCache)
                            console.log(examDataCache)
                          }
                          else
                            setExamFormData(item)
                          setExamFormShow(true)
                        }}
                      >
                        Edit Exam
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <div style={{ display: "inline" }}>
                {myarray}
              </div>

            </div>
            {/* {JSON.stringify(AllExamList)} */}
          </div>
          :
          <div>
            <div className="col-lg-3 col-6 p-2" style={{ cursor: 'pointer' }}
              onClick={() => {
                setExamFormShow(false)
              }}
            >
              <i class="fa fa-arrow-left"></i> Go back
            </div>
            <ExamFormComponent
              ExamFormData={ExamFormData}
              setAlertcomp={setAlertcomp}
              setAlertShow={setAlertShow}
              setReload={setReload}
            />
          </div>
        }
      </div>
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {AlertShow &&
        <AlertComponent />
      }
    </div>
  )
}