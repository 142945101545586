import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PostGETApiAuth } from '../../constants/api';
import SideBar from '../SideBar';

export default function Exam() {
  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const [AllExamList1, setAllExamList1] = React.useState([]);
  const [AllExamList2, setAllExamList2] = React.useState([]);
  const [AllExamList3, setAllExamList3] = React.useState([]);
  const [AllExamList4, setAllExamList4] = React.useState([]);
  const [AllExamList5, setAllExamList5] = React.useState([]);
  const [AllExamList6, setAllExamList6] = React.useState([]);
  const [AllExamList7, setAllExamList7] = React.useState([]);
  const [AllExamList8, setAllExamList8] = React.useState([]);
  const [AllExamList9, setAllExamList9] = React.useState([]);
  const [ExamFormShow, setExamFormShow] = React.useState(false);
  const [reload, setReload] = React.useState(false)
  const LoginState = useSelector((state) => state.LoginState);

  const fetchExam = (key, value, setAllExamList, perPage) => {
    setReload(true)
    PostGETApiAuth({
      ...perPage,
      // "filter[meta_query][1][key]": key,
      // "filter[meta_query][1][value][0]": value,
      // "filter[meta_query][1][compare]": "=",
      // "filter[meta_query][relation]": "AND",
      _fields: "meta,id,slug,title"
    }, 'exam-set')
      .then((res) => {
        setAllExamList(res.data)
        setReload(false)
        console.log(res.data.length)
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
      })
  }
  useEffect(() => {
    fetchExam("exam_type", "UBT", setAllExamList1, { per_page: 8, order: "asc" })
    // fetchExam("exam_type", "CBT CHEMICAL", setAllExamList2, { per_page: 8, order: "asc" })
    // fetchExam("exam_type", "CBT ELECTRONIC ELECTRCITY", setAllExamList3, { per_page: 8, order: "asc" })
    // fetchExam("exam_type", "CBT FOOD RELATED", setAllExamList4, { per_page: 8, order: "asc" })
    // fetchExam("exam_type", "CBT MACHINERY,MOLDING", setAllExamList5, { per_page: 8, order: "asc" })
    // fetchExam("exam_type", "CBT METAL", setAllExamList6, { per_page: 8, order: "asc" })
    // fetchExam("exam_type", "CBT PULP PAPER WOOD", setAllExamList7, { per_page: 8, order: "asc" })
    // fetchExam("exam_type", "CBT RUBBER", setAllExamList8, { per_page: 8, order: "asc" })
    // fetchExam("exam_type", "CBT TEXTILE SEWING", setAllExamList9, { per_page: 8, order: "asc" })
    // fetchExam("available_unavailable","Available")
  }, [])
  const AlertComponent = () => {
    return (
      <div className="modal"
        onClick={() => {
          setAlertShow(false)
        }}
      >
        <div>
          <div className="row " style={{ minHeight: "10vh", marginTop: "10vh" }}>
            <div className="col-lg-4 col-1"></div>
            <div className="col-lg-4 col-10 pb-5  bg-white  shadow">
              <div className="p-5">
                <div dangerouslySetInnerHTML={{ __html: Alertcomp }}></div>
              </div>
              <div style={{ display: 'flex', cursor: 'pointer' }} className="center">
                <div className="sign-in-button-1 col-8">
                  OK
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const ExamBlock = ({ item }) => {
    return (
      <div style={{
        backgroundColor: "#fff",
        padding: "5px", margin: "10px 0px", border: "1px solid black",
        //  height: "300px"
      }}>
        {item.meta.featured_image !== "" &&
          // <img src={item.meta.featured_image}
          <>
            <img
              src={item.meta.featured_image}
              // src={"https://ubtvirtualtest.com/api/wp-content/uploads/2022/06/UBT-DP-17.webp"}
              // src={"https://ubtvirtualtest.com/api/wp-content/uploads/2022/07/Logo-512-x-512-01-1CCC.png"}

              style={{
                objectFit: 'contain',
                // height: "200px",
                // width: "100px"
              }}
              className="col-12"
            />
            {/* <picture>
              <source srcSet={item.meta.featured_image} type="image/webp" />
              <source srcSet={item.meta.featured_image} type="image/jpeg" />
              <img src={item.meta.featured_image} alt="Alt text" />
            </picture> */}
          </>
        }
        {/* {item.meta.featured_image} */}
        <br />
        <div style={{ fontWeight: "bold" }}>{item.title.rendered}</div>
        {console.log(item.meta)}
        {item.meta.exam_question_block !== null ?
          <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
            {item.meta.available_unavailable == "Available" ?
              <Link className="sign-in-button-1 col-8 Link"
                to={`/exam/${item.slug}`}
              >
                <div style={{ color: "#fff" }}>
                  Start Exam
                </div>
              </Link>
              :
              <div className="sign-in-button-1 col-8 Link" style={{ color: "#000", backgroundColor: "#fff" }}>
                Exam Unavailable
              </div>
            }
          </div>
          :
          <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
            <div className="sign-in-button-1 col-8 Link" style={{ color: "#fff", opacity: 0.5 }}
            // to={`/exam/${item.slug}`}
            >
              Verify to Start Exam
            </div>
          </div>
        }
      </div>
    )

  }
  const userRole = localStorage.getItem('userRole');
  const HeaderBlock = ({ title, slug }) => {
    return (
      <div className="row">
        <div style={{ width: "70%" }}>
          <h2>{title}</h2>
        </div>

        <Link style={{ textDecorationLine: 'none', color: "#000", width: "30%", textAlign: 'right', alignItems: 'center' }}
          to={`/exam-type/${slug}`}
        >
          See More &nbsp;&nbsp;
          <i className="fa fa-chevron-right" />
          &nbsp;&nbsp;&nbsp;
        </Link>

      </div>
    )

  }
  return (
    <div>
      {/* {userRole} */}
      {AllExamList1.length !== 0 &&
        <>
          {AllExamList1[0].meta.exam_question_block == null &&

            <div style={{ width: "100%", backgroundColor: "#f00", padding: "7px", color: "#fff" }}
            >
              <Link className="Link" to="/profile" style={{ color: "#fff" }}>
                Verify your user first to start with exam
              </Link>
            </div>
          }
        </>
      }
      <div
        style={{ backgroundColor: "#eee" }}
        className="row"
      >
        <div className="col-md-2 ">
          <SideBar DashboardList={1} />
        </div>
        <div className="col-md-10 ">

          <div className="row" style={{ margin: "10px" }}>
            {AllExamList1.length !== 0 && <HeaderBlock title={"UBT Exams"} slug={"ubt"} />}
            {AllExamList1.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-6 center">
                <ExamBlock item={item} />
              </div>
            ))}
          </div>

          <div className="row" style={{ margin: "10px" }}>
            {AllExamList2.length !== 0 && <HeaderBlock title={"CBT CHEMICAL"} slug={"cbt-chemical"} />}
            {AllExamList2.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-6 center">
                <ExamBlock item={item} />
              </div>
            ))}
          </div>
          <div className="row" style={{ margin: "10px" }}>
            {AllExamList3.length !== 0 && <HeaderBlock title={"CBT ELECTRONIC ELECTRCITY"} slug={"cbt-electronics-electricity"} />}
            {AllExamList3.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-6 center">
                <ExamBlock item={item} />
              </div>
            ))}
          </div>
          <div className="row" style={{ margin: "10px" }}>
            {AllExamList4.length !== 0 && <HeaderBlock title={"CBT FOOD RELATED"} slug={"cbt-food-related"} />}
            {/* {AllExamList4.length !== 0 && <h2>CBT FOOD RELATED</h2>} */}
            {AllExamList4.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-6 center">
                <ExamBlock item={item} />
              </div>
            ))}
          </div>
          <div className="row" style={{ margin: "10px" }}>
            {AllExamList5.length !== 0 && <HeaderBlock title={"CBT MACHINERY,MOLDING"} slug={"cbt-mechinery-molding"} />}
            {/* {AllExamList5.length !== 0 && <h2>CBT MACHINERY,MOLDING</h2>} */}
            {AllExamList5.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-6 center">
                <ExamBlock item={item} />
              </div>
            ))}
          </div>
          <div className="row" style={{ margin: "10px" }}>
            {AllExamList6.length !== 0 && <HeaderBlock title={"CBT METAL"} slug={"cbt-metal"} />}
            {/* {AllExamList6.length !== 0 && <h2>CBT METAL</h2>} */}
            {AllExamList6.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-6 center">
                <ExamBlock item={item} />
              </div>
            ))}
          </div>
          <div className="row" style={{ margin: "10px" }}>
            {AllExamList7.length !== 0 && <HeaderBlock title={"CBT PULP PAPER WOOD"} slug={"cbt-pulp-paper-wood"} />}
            {/* {AllExamList7.length !== 0 && <h2>CBT PULP PAPER WOOD</h2>} */}
            {AllExamList7.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-6 center">
                <ExamBlock item={item} />
              </div>
            ))}
          </div>
          <div className="row" style={{ margin: "10px" }}>
            {AllExamList8.length !== 0 && <HeaderBlock title={"CBT RUBBER PLASTIC"} slug={"cbt-rubber"} />}
            {/* {AllExamList8.length !== 0 && <h2>CBT RUBBER</h2>} */}
            {AllExamList8.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-6 center">
                <ExamBlock item={item} />
              </div>
            ))}
          </div>
          <div className="row" style={{ margin: "10px" }}>
            {AllExamList9.length !== 0 && <HeaderBlock title={"CBT CBT TEXTILE SEWING"} slug={"cbt-textile-sewing"} />}
            {/* {AllExamList9.length !== 0 && <h2>CBT TEXTILE SEWING</h2>} */}
            {AllExamList9.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-6 center">
                <ExamBlock item={item} />
              </div>
            ))}
          </div>
        </div>
        {reload &&
          <div className="modal" style={{ padding: "50vw" }}>
            <div className="dot-pulse"></div>
          </div>
        }
      </div>
    </div>
  )
}