import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { PostGETApiAuth } from '../../constants/api';
import SideBar from '../SideBar';
const slug = {
  "ubt": "UBT",
  "cbt-chemical": "CBT CHEMICAL",
  "cbt-electronics-electricity": "CBT ELECTRONIC ELECTRCITY",
  "cbt-food-related": "CBT FOOD RELATED",
  "cbt-mechinery-molding": "CBT MACHINERY,MOLDING",
  "cbt-metal": "CBT METAL",
  "cbt-pulp-paper-wood": "CBT PULP PAPER WOOD",
  "cbt-rubber": "CBT RUBBER",
  "cbt-textile-sewing": "CBT TEXTILE SEWING"
}
export default function ExamType() {
  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const [AllExamList1, setAllExamList1] = React.useState([]);
  const [AllExamList2, setAllExamList2] = React.useState([]);
  const [AllExamList3, setAllExamList3] = React.useState([]);
  const [AllExamList4, setAllExamList4] = React.useState([]);
  const [AllExamList5, setAllExamList5] = React.useState([]);
  const [AllExamList6, setAllExamList6] = React.useState([]);
  const [AllExamList7, setAllExamList7] = React.useState([]);
  const [AllExamList8, setAllExamList8] = React.useState([]);
  const [AllExamList9, setAllExamList9] = React.useState([]);
  const [ExamFormShow, setExamFormShow] = React.useState(false);
  const [reload, setReload] = React.useState(false)
  const LoginState = useSelector((state) => state.LoginState);
  const [pagesLength, setPagesLength] = React.useState("1");
  const [pages, setPages] = React.useState(1);

  const fetchExam = (key, value, setAllExamList, perPage) => {
    setReload(true)
    PostGETApiAuth({
      ...perPage,
      "filter[meta_query][1][key]": key,
      "filter[meta_query][1][value][0]": value,
      "filter[meta_query][1][compare]": "=",
      "filter[meta_query][relation]": "AND",
    }, 'exam-set')
      .then((res) => {
        setAllExamList(res.data)
        setReload(false)
        setPagesLength(res.headers["x-wp-totalpages"])
        console.log(res.data.length)
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
      })
  }
  let myarray = []
  for (let i = 0; i < pagesLength; i++)
    myarray.push(
      <div key={i} style={{ margin: "3px" }}>
        <button onClick={() => {
          setPages(i + 1)
          setAllExamList1([])
          fetchExam("exam_type", slug[location.pathname.slice(11)], setAllExamList1, { page: i + 1, per_page: 20, order: "asc" })
          // fetchExam("exam_type", "UBT", setAllExamList1, { per_page: 20 })
          // GetAllProjects({ per_page: 100, page: i + 1 })
        }}
          style={{ border: "0", padding: "7px", backgroundColor: pages == i + 1 ? "#0d02a6" : "#f00", color: "#fff", borderRadius: "5px" }}
        >

          {i + 1}
        </button>
      </div>
    )
  const location = useLocation()
  useEffect(() => {
    // console.log(location)
    if (!location.pathname.slice(11).includes("topik"))
      fetchExam("exam_type", slug[location.pathname.slice(11)], setAllExamList1, { per_page: 20, order: "asc" })
    else
      setReload(false)
    console.log(location.pathname.slice(11))
  }, [])
  const AlertComponent = () => {
    return (
      <div className="modal"
        onClick={() => {
          setAlertShow(false)
        }}
      >
        <div>
          <div className="row " style={{ minHeight: "10vh", marginTop: "10vh" }}>
            <div className="col-lg-4 col-1"></div>
            <div className="col-lg-4 col-10 pb-5  bg-white  shadow">
              <div className="p-5">
                <div dangerouslySetInnerHTML={{ __html: Alertcomp }}></div>
              </div>
              <div style={{ display: 'flex', cursor: 'pointer' }} className="center">
                <div className="sign-in-button-1 col-8">
                  OK
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const ExamBlock = ({ item }) => {
    return (
      <div style={{ padding: "5px", margin: "10px 0px", border: "1px solid black",
      //  height: "250px" 
       }}>
        {item.meta.featured_image !== "" &&
          // <img src={item.meta.featured_image}
          <>
            <img
              src={item.meta.featured_image}
              style={{
                objectFit: 'contain',
                // height: "150px",
                // width: "100px"
              }}
              className="col-12"
            />

          </>
        }
        <br />
        {item.title.rendered}
        {console.log(item.meta.exam_question_block)}
        {item.meta.exam_question_block !== null ?
          <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
            {item.meta.available_unavailable == "Available" ?
              <Link className="sign-in-button-1 col-8 Link" 
                to={`/exam/${item.slug}`}
              >
                  <div style={{ color: "#fff" }}>
                  Start Exam
                </div>
              </Link>
              :
              <div className="sign-in-button-1 col-8 Link" style={{ color: "#000", backgroundColor: "#fff" }}>
                Exam Unavailable
              </div>
            }
          </div>
          :
          <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
            <div className="sign-in-button-1 col-8 Link" style={{ color: "#fff", opacity: 0.5 }}
            // to={`/exam/${item.slug}`}
            >
              Verify to Start Exam
            </div>
          </div>
        }
      </div>
    )

  }
  return (
    <div>

      <>
        {AllExamList1.length !== 0 &&
          <>
            {AllExamList1[0].meta.exam_question_block == null &&

              <div style={{ width: "100%", backgroundColor: "#f00", padding: "7px", color: "#fff" }}
              >
                <Link className="Link" to="/profile" style={{ color: "#fff" }}>
                  Verify your user first to start with exam
                </Link>
              </div>
            }
          </>
        }
      </>

      <div
        style={{ backgroundColor: "#fff" }}
        className="row"
      >
        <div className="col-md-2 ">
          <SideBar DashboardList={0} />
        </div>
        <div className="col-md-10 ">
          {location.pathname.slice(11).includes("topik") ?
            <div className="center">
              <h2 style={{ fontSize: 50, marginTop: 100 }}>Coming Soon...</h2>
            </div>
            :
            <div className="row" style={{ margin: "10px" }}>
              {AllExamList1.length !== 0 && <h2>{slug[location.pathname.slice(11)]}</h2>}
              {AllExamList1.map((item, index) => (
                <div key={index} className="col-lg-3 col-md-4 col-6 center">
                  <ExamBlock item={item} />
                </div>
              ))}
            </div>
          }

          {pagesLength !== "1" &&
            <div className="" style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
              {myarray}
            </div>
          }
        </div>
        {reload &&
          <div className="modal" style={{ padding: "50vw" }}>
            <div className="dot-pulse"></div>
          </div>
        }
      </div>
    </div>
  )
}