import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { PostGETApiAuth } from "../../constants/api";
import DataTable from "react-data-table-component";

import SideBar from './../SideBar';
const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.id,
    sortable: true,
    reorder: true,
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true
  },
  {
    id: 312,
    name: "Phone",
    selector: (row) => row.meta.user_phone_number,
    sortable: true,
    reorder: true
  },
  {
    id: 313,
    name: "Email",
    selector: (row) => row.meta.user_email,
    sortable: true,
    reorder: true
  },
  {
    id: 32,
    name: "role",
    selector: (row) => row.meta.user_role_user,
    sortable: true,
    reorder: true
  },
  {
    id: 3,
    name: "View",
    right: true,
    cell: (row) => <Link to={`/student/${row.slug}`}>View</Link>,

  }
];
export default function AllStudents() {
  const [Projects1, setProjects1] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const [serachPhone, setSearchPhone] = React.useState("")
  const GetAllProjects = (params) => {
    setReload(true)
    setProjects1([])
    PostGETApiAuth(params, 'users_data')
      .then((res) => {
        setProjects1(res.data)
        setCustomersList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetAllProjects({ per_page: 100, status: "" })

  }, [])

  return (
    <div>
      <div
        style={{ backgroundColor: "#fff" }}
        className="row"
      >
        <div className="row">
          <div className="col-2">
            <SideBar DashboardList={5} />

          </div>
          <div className="col-10" style={{ padding: "10px" }}>

            <div className="row" style={{ padding: "30px 30px" }}>
              <div className="col-md-8 button">
                <h2>All Students</h2>
              </div>
              <div className="col-md-4" style={{ textAlign: 'right' }}>
                <a href="/">Dashboard</a> / All Students
              </div>
            </div>
            <a href={"/new-student"}>
              <div className="sign-in-button-4" style={{ width: "150px", margin: "10px", textDecorationLine: "none" }}
                onClick={() => {

                }}
              >Add New Student</div>
            </a>
            <div className="col-md-6 row">
              <div className="col-1" />
              <input className="col-6" style={{ margin: "1px 2px", padding: "3px 2px" }}
                value={serachPhone} placeholder="Phone"
                onChange={(event) => {
                  setSearchPhone(event.target.value)
                  setProjects1(CustomersList.filter(a => a.meta.user_phone_number.includes(event.target.value)))
                }}
              />
            </div>
            <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px", border: "1px solid #999" }}>
              <DataTable
                columns={columns}
                data={Projects1}
                defaultSortFieldId={1}
                defaultSortAsc={false}
                striped
                // sortIcon={<SortIcon />}
                pagination
                selectableRows
              />

            </div>
            {Reload &&
              <div className="modal" style={{ padding: "50vw" }}>
                <div className="dot-pulse"></div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}