import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { PostGETApiAuth } from '../../constants/api';
import { NotFound } from '../../NotFound';
import { login, logout } from '../../redux/action';
import store from '../../redux/store';
import SideBar from '../SideBar';
import FirstScreenResult from './FirstScreen';

export default function DetailedResult() {
  const dispatch = useDispatch()
  const location = useLocation()
  const [ExamData, setAllExamList] = React.useState({});
  const [ExamFetch, setExamFetch] = React.useState(false);
  const [NotFoundData, setNotFound] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const [choose, setChoose] = React.useState(0)
  const [AnswerArray, setAnswerArray] = React.useState([]);
  const [ResultData, setResultData] = React.useState({});
  const fetchExam = (slug) => {
    setExamFetch(false)
    setReload(true)
    setNotFound(false)
    const id = store.getState().LoginState.userID;
    PostGETApiAuth({
      "filter[meta_query][1][key]": "student_id_results",
      "filter[meta_query][1][value][0]": id,
      "filter[meta_query][1][compare]": "=",
      slug: slug,
    }, "exam_results")
      .then((response) => {
        if (response.data.length !== 0) {
          setChoose(2)
          setResultData(response.data[0])
          setAnswerArray(JSON.parse(response.data[0].meta.exam_answers_array_results))
          PostGETApiAuth({}, `exam-set/${response.data[0].meta.exam_id_results}`)
            .then((res) => {
              setAllExamList(res.data)
              setExamFetch(true)
              console.log(JSON.parse(response.data[0].meta.exam_answers_array_results))

            })
            .catch(() => {
              setNotFound(true)
            })
            .finally(() => {
              setReload(false)
            })
        }
        else {
          setNotFound(true)
          setExamFetch(true)
          setReload(false)
        }
      })
      .catch(() => {
        setNotFound(true)
      })

  }
  useEffect(() => {
    fetchExam(location.pathname.slice(8))
  }, [])

  return (
    <div
      style={{ backgroundColor: "#fff" }}
    >
      <div className="row">
        <div className="col-1">
        </div>
        <div className="col-10" style={{ padding: "10px" }}>
          {ExamFetch &&
            <>
              {NotFoundData ?
                <NotFound /> :
                <FirstScreenResult
                  ExamData={ExamData}
                  setReload={setReload}
                  choose={choose}
                  setChoose={setChoose}
                  AnswerArray={AnswerArray}
                  setAnswerArray={setAnswerArray}
                  ResultData={ResultData}
                />
              }
            </>
          }
        </div>
      </div>
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}