import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function SideBar({ DashboardList }) {
  const LoginUser = useSelector((state) => state.LoginState);

  return (
    <div className="row">
     
      <Link className={DashboardList == 6 ? "active-sidebar col-md-12 col-3 Link" : "passive-sidebar col-md-12 col-3 Link"}
        to={`/`}
      >
        Dashboard
      </Link>
      <Link className={DashboardList == 1 ? "active-sidebar col-md-12 col-3 Link" : "passive-sidebar col-md-12 col-3 Link"}
        to={`/exam`}
      >
        Exam List
      </Link>
      <Link className={DashboardList == 2 ? "active-sidebar col-md-12 col-3 Link" : "passive-sidebar col-md-12 col-3 Link"}
        to={`/results`}
      >
        Results
      </Link>
      <Link className={DashboardList == 9 ? "active-sidebar col-md-12 col-4 Link" : "passive-sidebar col-md-12 col-4 Link"}
            to={`/exam-demo`}
          >
            Demo Exam
          </Link>
      {/* <Link className={DashboardList == 7 ? "active-sidebar col-md-12 col-3 Link" : "passive-sidebar col-md-12 col-3 Link"}
        to={`/demo-exams`}
      >
        Demo Exams
      </Link> */}
      {/* <span className="d-sm-block d-none" > */}
        <Link className={DashboardList == 3 ? "active-sidebar col-sm-12 col-3 Link " : "passive-sidebar col-md-12 col-3 Link "}
          to={`/profile`}
        >
          Profile
        </Link>
      {/* </span> */}
      {LoginUser.userRole == "author" &&
        <>
          <Link className={DashboardList == 4 ? "active-sidebar col-md-12 col-4 Link" : "passive-sidebar col-md-12 col-4 Link"}
            to={`/add-exam`}
          >
            Manage Exam
          </Link>
          <Link className={DashboardList == 5 ? "active-sidebar col-md-12 col-4 Link" : "passive-sidebar col-md-12 col-4 Link"}
            to={`/all-students`}
          >
            Manage Students
          </Link>
          <Link className={DashboardList == 8 ? "active-sidebar col-md-12 col-4 Link" : "passive-sidebar col-md-12 col-4 Link"}
            to={`/add-exam-demo`}
          >
            Manage Demo Exam
          </Link>
          
        </>
      }
    </div>
  )
}