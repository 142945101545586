import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PostGETApiAuth } from '../../constants/api';
import store from '../../redux/store';
import SideBar from '../SideBar';

export default function Results() {
  const location = useLocation()
  const [ResultData, setAllResultList] = React.useState([]);
  const [ResultFetch, setResultFetch] = React.useState(false);
  const [NotFoundData, setNotFound] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const [choose, setChoose] = React.useState(0)
  const [AnswerArray, setAnswerArray] = React.useState([]);

  const AllResultsCall = () => {
    setResultFetch(false)
    setReload(true)
    setNotFound(false)
    const id = store.getState().LoginState.userID
    PostGETApiAuth({
      "filter[meta_query][1][key]": "student_id_results",
      "filter[meta_query][1][value][0]": id,
      "filter[meta_query][1][compare]": "=",
    }, "exam_results")
      .then((response) => {
        setAllResultList(response.data)
        setResultFetch(true)
        if (response.data.length == 0)
          setNotFound(true)
      })
      .finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    AllResultsCall()
  }, [])
  return (
    <div
      style={{ backgroundColor: "#fff" }}
      className="row"
    >
      <div className="row">
        <div className="col-2">
        <SideBar DashboardList={2} />

        </div>
        <div className="col-10" style={{ padding: "10px" }}>
          <div style={{ border: "1px solid #aaa", padding: "30px", }}>

            <h2>List of Results</h2>
            <div className="row row-data-result">
              <div className="col-md-3 center">Exam Name</div>
              <div className="col-md-3 center">Obtained Marks</div>
              <div className="col-md-3 center">Exam Date</div>
            </div>
            {ResultData.map((item, data) => (
              <div className="row row-data-result"
                key={data}
              >
                <div className="col-md-3 center">{item.meta.exam_name_results}</div>
                <div className="col-md-3 center">{item.meta.exam_total_results}</div>
                <div className="col-md-3 center">{item.meta.exam_date_results}</div>
                <div className="col-md-2 center">
                  <Link to={`/result/${item.slug}`}>
                    <button className="sign-in-button-1">View Result</button>
                  </Link>
                </div>
              </div>
            ))}

            {ResultData.map((item, data) => (
              <div
                className="row-data-result-phone"
                key={data}
              >
                <div className="row row-data-result-phone2">
                  <div className="col-6">Exam Name</div>
                  <div className="col-6">{item.meta.exam_name_results}</div>
                </div>
                <div className="row row-data-result-phone2">
                  <div className="col-6">Obtained Marks</div>
                  <div className="col-6">{item.meta.exam_total_results}</div>
                </div>
                <div className="row row-data-result-phone2">
                  <div className="col-6">Exam Date</div>
                  <div className="col-6">{item.meta.exam_date_results}</div>
                </div>
                <div className="col-12 center">
                  <Link to={`/result/${item.slug}`}>
                    <button className="col-md-2 center sign-in-button-1">View Result</button>
                  </Link>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div >
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div >
  )
}