import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import store from '../redux/store';
export const apiUrl = 'https://ubttest.skysewa.com/wp-json/';
// export const apiUrl = 'http://ubttest.skysewa.com/wp-json/';
export const consumerKey="ck_b701c2e3890433049e7faed0ed9ed1102974609e";
export const consumerSecret = "cs_7aee050a9f340d9e68e1135c5030b9e00456874e";

export const ImageURL = `${apiUrl}wp/v2/media/`;

export const PostGETApi = (params, slug) => {
  return axios({
    method: "GET",
    url: `${apiUrl}wp/v2/${slug}?_embed`,
    params: params
  })
}

export const PostGETApiAuth = (params, slug) => {
  const token = store.getState().LoginState.token
  return axios({
    method: "GET",
    url: `${apiUrl}wp/v2/${slug}`,
    params: params,
    headers: {
      "Authorization": `Basic ${token}`,
      // "Authorization": `Bearer ${token}`,
    },
  })
}

export const AdsGETApiWithToken = (params, slug, token) => {
  // const token = store.getState().LoginState.token
  return axios({
    method: "GET",
    url: `${apiUrl}wp/v2/${slug}`,
    params: params,
    headers: {
      "Authorization": `Basic ${token}`,
      // "Authorization": `Bearer ${token}`,
    },
  })
}

export const AdsPOSTApiWithoutAuth = (data, slug) => {
  return axios({
    method: "POST",
    url: `${apiUrl}wp/v2/${slug}`,
    headers: {
      // "Authorization": `Basic ${token}`,
      // "Authorization": `Bearer ${token}`,
    },
    data: data
  })
}

export const PostPOSTApi = (data, slug) => {
  const token = store.getState().LoginState.token;
  return axios({
    method: "POST",
    url: `${apiUrl}wp/v2/${slug}`,
    headers: {
      "Authorization": `Basic ${token}`,
      // "Authorization": `Bearer ${token} `,
    },
    data: data
  })
}


export const AdsPOSTApi = (data, slug) => {
  const token = store.getState().LoginState.token;
  console.log(token)
  return axios({
    method: "POST",
    url: `${apiUrl}wp/v2/${slug}`,
    headers: {
      "Authorization": `Basic ${token}`,
      // "Authorization": `Bearer ${token}`,
    },
    data: data
  })
}
export const AdsPOSTApiWithToken = (data, slug, token) => {
  // const token = store.getState().LoginState.token;
  // console.log(token)
  return axios({
    method: "POST",
    url: `${apiUrl}wp/v2/${slug}`,
    headers: {
      "Authorization": `Basic ${token}`,
      // "Authorization": `Bearer ${token}`,
    },
    data: data
  })
}
export const PostPOSTNewApi = (data, slug,token) => {
  // const token = store.getState().LoginState.token;
  return axios({
    method: "POST",
    url: `${apiUrl}wp/v2/${slug}`,
    headers: {
      "Authorization": `Basic ${token}`,
      // "Authorization": `Bearer ${token} `,
    },
    data: data
  })
}

export const PostPUTApi = async (data, slug, id) => {
  const token = store.getState().LoginState.token
  return await axios({
    method: "PUT",
    url: `${apiUrl}wp/v2/${slug}/${id}`,
    headers: {
      "Authorization": `Basic ${token}`,
      // "Authorization": `Bearer ${token} `,
    },
    data: data
  })
}

export const PostDeleteApi = async (slug, id) => {
  const token = store.getState().LoginState.token
  return await axios({
    method: "DELETE",
    url: `${apiUrl}wp/v2/${slug}/${id}`,
    headers: {
      "Authorization": `Basic ${token}`,
      // "Authorization": `Bearer ${token} `,
    },
    data: {
      id: id,
      force: true
    }
  })
}

export const userSignIn = async (username, password) => (
  await axios({
    method: "POST",
    url: `${apiUrl}jwt-auth/v1/token`,
    params: {
      username: username,
      password: password
    }
  })
)

export const UserSignUp = async (data) => {
  await axios({
    method: "POST",
    // url:`${apiUrl}`,
    url: `${apiUrl}wp/v2/users`,
    data: data,
  })
}

export const UserMe = (token) => {
  return axios({
    method: "GET",
    headers: {
      "Authorization": `Basic ${token}`,
      // "Authorization": `Bearer ${token}`,
    },
    url: `${apiUrl}wp/v2/users/me`,
  })
}

export const UserMeEdit = (data) => {
  const token = store.getState().LoginState.token
  return axios({
    method: "PUT",
    headers: {
      "Authorization": `Basic ${token}`,
      // "Authorization": `Bearer ${token}`,
    },
    url: `${apiUrl}wp/v2/users/me`,
    data: data,
  })
}

export const MediaUpload = (file) => {
  const token = store.getState().LoginState.token
  return (
    fetch(`${ImageURL}`, {
      method: "POST",
      headers: {
      "Authorization": `Basic ${token}`,
      // "Authorization": `Bearer ${token} `,
        "Content-Type": `${file.type}`,
        'Content-Disposition': `attachment; filename = "${file.name}"`,
      },
      body: file
    })
  )
}

export const sendOTPEmail = (email, message,subject) => {
  const formData = new FormData();
  formData.append("your-email", email);
  formData.append("your-subject", subject);
  formData.append("your-message", message);
  return fetch(`${apiUrl}/contact-form-7/v1/contact-forms/2513/feedback`, {
    method: 'POST',
    body: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}