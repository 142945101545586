import React, { useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import { PostGETApiAuth } from "../../constants/api";
import { NotFound } from "../../NotFound";
import SideBar from "../SideBar";
import FormComponent from "./Components/Form";

export default function StudentDetails(props) {
  const [NotFoundData, setNotFound] = React.useState(false)
  const [FetchDone, setFetchDone] = React.useState(false)
  const [FetchData, setFetchData] = React.useState({})
  const location = useLocation()
  // const history = useHistory()
  const AdsFetch = (slug) => {
    PostGETApiAuth({
      slug: slug,
      status:"any",
      _fields: "id,title,slug,meta"
    }, "users_data")
      .then(res => {
        console.log(res.data)
        if (res.data.length == 0)
          setNotFound(true)
        else {
          setFetchData(res.data[0])
        }
        setTimeout(() => {
          setFetchDone(true)
        }, 100)
      }).catch((e) => {
        console.log(e)
        setNotFound(true)
        setTimeout(() => {
          setFetchDone(true)
        }, 100)
      })
  }
  useEffect(() => {
    AdsFetch(location.pathname.slice(9))
    //   return history.listen((location) => {
    //     if (location.pathname.includes(slugs.student_details)) {
    //       window.scrollTo(0, 0)
    //       AdsFetch(location.pathname.slice(slugs.student_details.length))
    //     }
    //   })
    // }, [history])
  }, [])
  return (
    <div>
      <div
        style={{ backgroundColor: "#fff" }}
        className="row"
      >
        <div className="row">
          <div className="col-2">
            <SideBar DashboardList={5} />

          </div>
          <div className="col-10" style={{ padding: "10px" }}>

            <div>
              {FetchDone ?
                <div>
                  {NotFoundData ?
                    <NotFound /> :
                    <div>
                      <div className="row" style={{ padding: "30px 30px" }}>
                        <div className="col-md-8 button">
                          <h2>{FetchData.title.rendered}.</h2>
                        </div>
                        <div className="col-md-4" style={{ textAlign: 'right' }}>
                          <a href="/">Dashboard</a> / <a href={"all-students"}>All Students</a>
                        </div>
                      </div>
                      <FormComponent
                        FetchData={FetchData}
                      />
                    </div>
                  }
                </div> :
                <div className="modal" style={{ padding: "50vw" }}>
                  <div className="dot-pulse"></div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}